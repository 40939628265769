@import "../../../../../../../../../variables";

.SetBrowseConfigurationModal {
  width: 100vw;
  max-width: 55em;

  .SetBrowseConfigurationModal-inner {
    height: 75vh;
    overflow: auto;

    .BrowseItem {
      margin: 10px 10px 5px;
      flex: 1;
      display: flex;
      flex-direction: row;
      border: 1px solid #000;
      border-radius: 5px;

      .BrowseItemIndex {
        background-color: $black;
        padding: 10px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
      }

      .BrowseItem-inner {
        flex: 1;
      }
    }

    .Actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding: 0 10px 10px;

      .ant-btn {
        margin-left: 5px;
      }
    }
  }

  .Actions {
    display: flex;
    justify-content: space-between;
    flex: 1;

    .Inner {
      display: flex;
    }
  }

  .HL-List {
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #ddd;
    border-top: none;

    li {
      padding: 0.4em 1em;
      border-top: 1px solid #ddd;

      .HL-Title,
      .HL-Value {
        display: inline-block;
      }

      &:first-of-type .HL-Value {
        font-weight: 700;
        font-size: 110%;
      }

      .HL-Value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 30em;
        vertical-align: middle;
      }

      .HL-Title {
        min-width: 16em;
        font-weight: 700;
      }
    }
  }
}