@import "../../../../../../../../../../../variables";

$border-width: 2px;
$border-color: $purple-dark;

.CollapsiblePanel {

  &.ant-collapse {
    border: none;

    > .ant-collapse-item {
      margin-bottom: 10px;
      border-radius: 8px !important;
      border-top: $border-width solid $border-color;
      border-left: $border-width solid $border-color;
      border-right: $border-width solid $border-color;

      .ant-collapse-content {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .ant-collapse-header {
        border-bottom: $border-width solid $border-color;
        border-radius: 8px !important;
        border-top-right-radius: 4px !important;
        border-top-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }

      &.ant-collapse-item-active {
        border-bottom: $border-width solid $border-color;

        .ant-collapse-header {
          border-bottom: none;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }
    }
  }
}