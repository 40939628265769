@import "../../../variables";

.BaseImagesMosaicDisplay {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;

  .BaseImagesMosaicDisplay-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .Image-Container {
      position: relative;

      &:hover {
        .ImagesGridDisplay-actions {
          display: flex;
          cursor: pointer;
        }
      }
  
      .ImagesGridDisplay-actions {
        display: none;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: flex-end;
        padding: 5px;
  
        .ImageGridDisplay-overlay {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          min-width: 0;
          background-color: $grey-light;
          opacity: .8;
        }
      }
    }

    .ImageComponent {
      width: 100vw;
      height: 150px;
      max-width: 200px;

      .imageloader {
        width: auto;
      }
    } 
  } 
}