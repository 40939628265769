@import "../../variables";

.BaseHorizontalNavigation {
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: $base-horizontal-navigation-background;
  justify-content: space-between;
  width: 100vw;
  overflow: auto;

  .BaseHorizontalNavigation-links {
    display: flex;
    flex-direction: row;

    .BaseHorizontalNavigation-item {
      cursor: pointer;
      height: auto;
      line-height: 30px;

      .BaseHorizontalNavigation-placeholder {
        padding: 2px 10px 0;
        font-weight: bold;
        border-right: 1px solid $white;
        color: $base-horizontal-navigation-placeholder;
        text-transform: uppercase;
        white-space: nowrap;
      }

      .BaseHorizontalNavigation-bottomLine {
        border-bottom: 6px solid $base-horizontal-navigation-bottom-line;
      }

      &.selected {

        .BaseHorizontalNavigation-placeholder {
          font-weight: bold;
          color: $base-horizontal-navigation-bottom-line-selected;
        }

        background-color: $white;

        .BaseHorizontalNavigation-bottomLine {
          border-bottom-color: $base-horizontal-navigation-border;
        }
      }
    }
  }

  .BaseHorizontalNavigation-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 30px;
  }

  @media (min-width: 1000px) {
    width: auto;
  }
}