@import '../../../../../../../../../../variables';

.AddMoviesTop10Modal {
  height: 500px;
  width: calc(100vw - 20px);
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;

  .SearchInput {
    .ant-input {
      min-height: auto;
    }
  }

  .BaseCard {
    width: calc(50% - 5px);
    margin-top: 10px;

    .ImageComponent {
      height: 150px;
      width: 100px;
    }

    .Title {
      font-size: 16px;
      font-weight: bold;
    }

    .ant-checkbox-wrapper {
      justify-content: flex-end;
      display: flex;
    }
  }

  @media (min-width: 1000px) {
    width: 650px;
    height: 600px;

    .BaseCard {
      width: calc(33% - 5px);
    }
  }
}

.AddMoviesTop10Modal-actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}
