@import "../../../../../../../../../../../../variables";

.AdDetails {
  .AdCardTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .BaseCard-body-children {
    padding: 0 !important;
  }

  .AdFooter {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;

    .ant-btn {
      background-color: $purple-light;
      color: $white;
      border-color: $purple-dark;
    }
  }
}