@import '../../../../../../../../../../../../variables';

.AddPlaylistModal {
  width: calc(100vw - 20px);
  height: calc(100vh - 130px);
  padding: 10px;
  display: flex;
  flex-direction: column;

  .AddPlaylistModal-inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    
    .AddPlaylistModal-inner-label {
      font-weight: bold;
      font-size: 1rem;
    }

    .ant-input-number {
      width: 100%;
    }
  }

  @media (min-width: 1000px) {
    width: 500px;
    height: 500px;
    padding: 20px;
  }
}

.CategoryDropdownItemWrapper {
  display: flex;
  flex-direction: row;
  
  .CategoryDropdownItemText {
    flex: 1;
  }
}

.AddPlaylistModal-actions {
  button:last-child:disabled {
    color: $purple-dark !important;
  }
}
