.ConfigurePlayer {
  width: 90vw;
  height: 90vh;

  .ConfigurePlayer-inner {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    overflow: auto;

    .NoProperties {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .Actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    min-width: 0;
  }
}