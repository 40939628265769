@import "../../variables";

.ImagesGridDisplay {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .ImagesGridDisplay-inner {
    display: flex;
    flex-direction: row;
    flex: 1;
    min-width: 0;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ImagesGridDisplay-card {
    border-radius: 10px;
    margin-right: 0;
    margin-bottom: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &:hover {
      .ImagesGridDisplay-actions {
        display: flex;
        cursor: pointer;
      }
    }

    .ImagesGridDisplay-actions {
      display: none;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 10px;

      .ImageGridDisplay-overlay {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        min-width: 0;
        background-color: $grey-light;
        opacity: .8;
      }
    }

    > .ant-card-cover {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 20px;

      .ImagesGridDisplay-card-image {
        max-width: 300px;
        max-height: 300px;
        width: auto;
      }
    }

    >.ant-card-body {
      width: 100%;

      .ant-card-meta-title {
        word-break: break-all;
        text-overflow: initial;
        white-space: normal;
      }
    }
  }

  @media (min-width: 500px) {

    .ImagesGridDisplay-card {
      margin-right: 20px;
    }
  }
}
