.AddSubCategoryModal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100vw - 20px);
  height: 500px;
  overflow: auto;
  
  .AddSubCategoryModal-inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
      
    .AddSubCategoryModal-inner-label {
      font-weight: bold;
      font-size: 1rem;
    }
  
    .ant-input-number {
      width: 100%;
    }
  }
  
  @media (min-width: 1000px) {
    width: 500px;
  }
}