/* @import "../../../variables"; */

.ConfirmationModal {
  width: 400px;
  height: 150px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: white;

  .anticon {
    font-size: 32px;
    margin-right: 10px;
  }

  div:last-child {
    margin-top: 3px;
  }
}
