@import '../../variables';

.BaseSort {
  display: flex;
  flex-direction: row;
  align-items: center;
  .ant-select {
    flex: 1;
    margin-right: 0;
  }

  @media (min-width: 1000px) {
    margin-right: 15px;
  }

  .label {
    font-weight: bold;
    margin-right: 5px;
  }

  .BaseSortDropdownItemWrapper {
    .BaseSortDropdownItem {
      display: none;
    }
  }

  @media (min-width: 1000px) {
    .ant-select-selection {
      width: 300px;
    }
    input {
      width: 300px;
    }
  }
}

.BaseSortDropdownItemWrapper {
  display: flex;
  flex-direction: row;

  .BaseSortDropdownItemText {
    flex: 1;
  }

  .BaseSortDropdownItem {
    margin-left: 10px;
    text-decoration: underline;

    &.SelectedSort {
      color: $purple-light;
      font-weight: bold;
    }
  }

  .BaseSortDropdownItem:hover {
    color: $blue-lighter;
    font-weight: bold;
  }
}
