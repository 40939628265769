@import "../../../../../../../../../../variables";

.SelectMoviesModal {
  height: 600px;
  width: 800px;

  .SelectMoviesModal-inner {
    flex: 1;
    min-height: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .FilterWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 10px;

      .Label {
        font-weight: bold;
        white-space: nowrap;
        margin-right: 10px;
      }
    }

    .MoviesList {
      overflow: auto;
      flex: 1;
      min-height: 0;

      .MovieItem {
        border: 1px solid $ant-border;
        padding: 8px;
        border-radius: 5px;
        margin-bottom: 2px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .MovieTitle {
          font-weight: bold;
        }

        &.Checked {
          background-color: $blue-lighter;
        }

        &:hover {
          background-color: $blue-light;
        }
      }
    }
  }
}