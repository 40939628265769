.StarFiltersWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .InputWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-right: 10px;

    div:first-child {
      white-space: nowrap;
    }
  }

  @media (min-width: 1050px) {
    flex-direction: row;

    .InputWrapper {
      align-items: center;
      flex-direction: row;

      > div, input {
        margin-right: 10px;
      }

      .ant-select {
        width: 300px;
      }
    }
  }
}