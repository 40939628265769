.EditThemeModal {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: calc(100vw - 20px);
    height: 80vh;
    overflow: auto;

    .EditThemeModal-inner {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        &:last-of-type {
            flex-direction: row;
            align-items: center;
            min-height: 32px;

            .ant-checkbox-wrapper {
                margin: 0 10px;
            }
        }

        .EditThemeModal-inner-label {
            font-weight: bold;
            font-size: 1rem;
        }

        .ant-input-number {
            width: 100%;
        }
    }

    @media (min-width: 1000px) {
        width: 500px;
    }
}
