@import "~@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css";
@import "./variables";

.App section:nth-child(1) {
  height: calc(100vh - 56px);

  @media (min-width: 1000px) {
    height: 100vh;
  }
}

.App {
  .MiniPlayerContainer-dragContainer {
    height: fit-content;
    position: absolute;
    right: 5px;
    bottom: 70px;
    width: calc(100vw - 10px);
  }

  .SuspenseLoader {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  @media (min-width: 1000px) {
    .MiniPlayerContainer-dragContainer {
      right: 50px;
      bottom: 150px;
      width: 700px;
    }
  }

  textarea.ant-input {
    height: 32px;
  }   
}

.NavigationLink {
  color: $link-color;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.text-bold-600 {
  font-weight: 600;
}

.text-center {
  text-align: center;
}
