.BaseVirtualList {
  overflow: scroll;
  max-height: 90%;

  .NoBannersMsg {
    margin-right: 10px;
    width: 100%;
    font-weight: bold;
    white-space: nowrap;
    color: #cccccc;
    text-transform: uppercase;
    text-align: center;
  }
}