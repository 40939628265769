.SampleVideoConfiguration {
    display: flex;
    width: 50vw;
    max-height: 250px;
    flex-direction: column;
    overflow-y: scroll;

    .Sample {
        height: 50px;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-around;
        padding: 5px;
        align-items: center;

        .Index {
            align-items: center;
            justify-content: center;
            align-content: flex-end;
            align-self: end;
            padding: 10px;
        }

        .Percentage,
        .Duration {
            flex-direction: column;
            padding: 10px;
        }

        .RemoveBtn {
            padding: 10px;
            align-items: center;
            justify-content: center;
            align-content: flex-end;
            align-self: end;
        }
    }
}

.SampleVideoConfiguration-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .Section {
        width: 100%;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }
}
