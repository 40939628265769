@import "../../../../../../../../variables.scss";

.StreamingUsers {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    background-color: $white;

    .StreamingUsers-inner {
        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
    }

    .UsersViewHeader {
        padding: 10px;
        display: flex;
        flex-direction: column;
        background-color: $white-grey;

        .UsersViewHeader-mainWrapper {
            display: none;
            flex-direction: column;
            align-items: stretch;
            @media (min-width: 1000px) {
                align-items: center;
            }

            &.FiltersToggled {
                display: flex;
            }

            .UsersViewHeader-filterLabel {
                margin: 0 10px;
                font-weight: bold;
                min-width: max-content;
            }

            .UsersViewHeader-filterWrapper:first-child {
                flex: 1;
            }

            .UsersViewHeader-filterWrapper {
                display: flex;
                flex-direction: column;
                align-items: stretch;

                .ant-select {
                    width: 100%;
                    @media (min-width: 1000px) {
                        min-width: 150px;
                    }
                }

                .ant-calendar-picker {
                    input {
                        @media (min-width: 1000px) {
                            min-width: 220px;
                        }
                    }
                }

                input {
                    flex: 1;
                    width: 100%;
                    @media (min-width: 1000px) {
                        min-width: 350px;
                    }
                }

                input[type="number"] {
                    flex: 1;
                    @media (min-width: 1000px) {
                        min-width: 200px;
                    }
                }
            }

            .UsersViewHeader-filterWrapper:nth-child(2) {
                margin-left: 0;
                margin-bottom: 10px;
                min-width: max-content;
            }

            button {
                margin-left: 0;
            }
        }

        .UsersViewHeader-inner {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            font-weight: bold;
            padding-right: 5px;

            .anticon {
                margin-left: 20px;
            }
        }

        @media (min-width: 1000px) {
            flex-direction: row;

            .UsersViewHeader-mainWrapper {
                flex: 1;
                min-width: 0;
                display: flex;
                flex-direction: row;
                overflow: auto;

                .UsersViewHeader-filterWrapper {
                    flex-direction: row;
                    align-items: center;
                }

                .UsersViewHeader-filterWrapper:nth-child(2) {
                    margin-left: 10px;
                    margin-bottom: 0;
                }

                button {
                    margin-left: 10px;
                }
            }

            .UsersViewHeader-inner {
                display: none;
                margin-bottom: 0;
            }
        }
    }

    .UsersTable {
        flex: 1;
        display: none;
        flex-direction: column;
        min-height: 0;

        .ant-spin-nested-loading {
            display: flex;
            flex-direction: column;
            flex: 1;
            min-height: 0;

            .ant-spin-container {
                display: flex;
                flex-direction: column;
                flex: 1;
                min-height: 0;

                .ant-table {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    min-height: 0;

                    .ant-table-content {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        min-height: 0;

                        .ant-table-scroll {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            min-height: 0;
                        }

                        .ant-table-body {
                            overflow: auto;
                            flex: 1;
                            min-height: 0;
                            background-color: $white;

                            .ant-select {
                                width: 100%;
                                @media (min-width: 1000px) {
                                    min-width: 150px;
                                }
                            }

                            .NavigationLink {
                                font-weight: bold;
                                text-decoration: underline;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                            }
                        }

                        .ant-table-placeholder {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .ListView {
        display: block;
        flex: 1;
        min-height: 0;
        overflow: auto;

        .LinkButton {
            float: right;
        }

        .BaseCard {
            margin: 10px;

            .ant-card-head {
                color: $purple-light;
                font-weight: bold;
                font-size: 1rem;
            }

            .BaseCard-body-children {
                padding: 0;

                table {
                    border-left-width: 0;
                    border-top-width: 0;

                    tr {
                        td:nth-child(1) {
                            font-weight: bold;
                            border-left-width: 0;
                        }

                        td:nth-child(2) {
                            font-weight: bold;
                            border-right-width: 0;
                        }
                    }

                    .ant-select {
                        width: 100%;
                        @media (min-width: 1000px) {
                            min-width: 150px;
                        }
                    }

                    tr:last-child {
                        td {
                            border-bottom-width: 0;
                        }
                    }
                }
            }

            > button {
                border-color: $purple-light;
            }
        }
    }

    @media (min-width: 1000px) {
        .ListView {
            display: none;
        }

        .UsersTable {
            display: flex;
        }
    }

    .StatusItem {
        font-weight: bold;
        font-size: 1rem;
        padding: 5px;

        .UserEnabled {
            background-color: $user-enabled;
            color: $white;
        }

        .UserDisabled {
            background-color: $user-disabled;
            color: $white;
        }
    }
}
