@import "../../../../../../../../../../variables";

.PayoutsByPropertyMainView {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: $white;
  overflow: auto;
  transform: translate3d(0, 0, 0);

  .PayoutsByPropertyMainViewBody {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
 
    .ant-table-thead > tr > th, 
    .ant-table-tbody > tr > td {
      white-space: nowrap;
    }

    .ActionContainer {
      text-align: left;
    
      .ActionBtn {
        height: auto;
        margin: 0 0 0 5px;
        border: 1px solid #7a7de5;
        padding: 4px 15px;
      }  
    }

    .ant-table-placeholder {
      display: none;
    } 

    .ant-list {
      flex: 1;
      min-height: 0;
      display: block;
      overflow: auto;

      li {
        padding: 0;
        margin-bottom: 10px;

        .ListCardWrapper {

          .ListItemLabel {
            font-weight: bold;
            margin-right: 5px;
          }

          p {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .ListCardCFooter {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          button:first-child {
            margin-right: 15px;
          }

          button {
            border-color: $purple-light;
          }
        }
      }

      li:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: 1000px) {
      padding: 0; 

      .ant-list {
        display: none;
      }
    }
  }
}