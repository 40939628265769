@import "../../../../../../../../../../../../../../variables";

.PlaylistMovieSelect {
  margin-bottom: 20px;
  
  div:first-child {
    font-weight: bold;
  }
}

.PlaylistMovieSelect-dropdown {

  .PlaylistMovieSelect-dropdown-divider {
    margin: 1px 0;
  }

  .PlaylistMovieSelect-loadMore {
    color: $purple-light;
    padding: 4px 8px;
    cursor: pointer;
  }

  .PlaylistMovieSelect-spinner {
    margin: 4px 10px;
  }
}