.SceneDetailsImagesTab {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 10px;

  .BaseImagesDisplay {
    flex: 1;
    min-height: 0;
  }
}