@import "../../variables";

.LoadingIndicator {
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: center;
	flex-direction: column;

	.ant-spin {
		margin-right: 10px;
		margin-top: 5px;
	}

	.LoadingText {
		display: none;
		font-size: 1rem;
		font-weight: bold;

		@keyframes blink {
			0% {
				opacity: 0.2;
			}
			20% {
				opacity: 1;
			}
			100% {
				opacity: 0.2;
			}
		}

		span {
			animation-name: blink;
			animation-duration: 1.4s;
			animation-iteration-count: infinite;
			animation-fill-mode: both;
		}

		span:nth-child(2) {
			animation-delay: 0.2s;
		}

		span:nth-child(3) {
			animation-delay: 0.4s;
		}
	}

	&.Loading {
		top: 0;
		left: 0;
		position: fixed;
		height: 100%;
		width: 100%;

		&:before {
			position: absolute;
			display: block;
			content: "";
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: $white-grey;
			opacity: 0.6;
			z-index: -1;
		}
	}

	@media (min-width: 1000px) {
		flex-direction: row;
		.LoadingText {
			display: block;
		}
	}
}
