@import "../../../../../../../../../../variables";

.ContentInfoView {
  flex: 1;
  min-height: 0;
  background-color: $white;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .ContentInfoView-inner {

    .Title {
      font-weight: bold;
      font-size: 25px;
      margin-bottom: 20px;
      margin-left: 20px;
      margin-top: 20px;
    }

    .Field {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      margin-left: 20px;

      .Label {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .BaseCard {
      margin: 0 10px 10px 10px;
      width: max-content;
      min-width: calc(100% - 20px);

      .ResponseText {
        font-size: 15px;
        font-weight: bold;

        pre {
          width: max-content;
        }
      }
    }

    @media (min-width: 1000px) {
      .Field {
        margin-left: 50px;
      }
    }
  }
}