@import '../../../../../../../../../../variables.scss';

.LicensorDetailsBody {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;

  > :first-child {
    flex: auto;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  .LicensorDetailsBody-inner {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1000px) {
    .LicensorDetailsBody-inner {
      flex-direction: row;
    }
  }
}
