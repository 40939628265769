@import "../../../../../../../../../../variables";

.DefaultBannerSets {
  transform: translate3d(0, 0, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;

  .Inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    padding: 20px;
    overflow: auto;
    overflow-x: hidden;

    .BannerSetCard {
      margin-bottom: 12px;
    }

    .BannerSetGroup {
      font-size: 30px;
      border-radius: 8px;
      background-color: #6394c7;
      color: #ffffff;
      padding: 8px 24px;
      font-weight: 600;
      margin-bottom: 10px;
      box-shadow: 5px 5px 10px rgba(0,0,0, .4);
      cursor: pointer;
      position: relative;

      .toggleIcon {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 80%;
      }
    }
  }
}