@import '../../../../../../../../../variables';

.ConfigureCategoriesModal {
  height: 75vh;
  width: calc(100vw - 20px);
  padding: 10px;
  display: flex;
  flex-direction: row;

  .BaseDragDropList {
    width: 100%;

    .DraggableItem {
      margin-bottom: 5px;

      .ItemContainer {
        width: 100%;
        pointer-events: none;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;

        .IndexContainer {
          display: flex;
          width: 50px;
          justify-content: center;
          align-items: center;
          background: $black;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;

          h2 {
            color: $brown;
          }
        }

        .ItemDetails {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 70px;
          padding-left: 10px;
          border: 1px solid $purple-dark;
          border-right-width: 0;

          .Item-Title {
            font-weight: bold;
            margin: 10px 10px 10px 0;
            display: flex;
            align-items: flex-end;
            width: 60%;
            font-size: 20px;
          }
        }
      }

      .CloseContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid $purple-dark;
        border-left-width: 0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 15px;
        font-size: 30px;
      }
    }
  }

  @media (min-width: 1000px) {
    width: 600px; 
  }
}

.ConfigureCategoriesModal-actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
