@import '../../../../../../../../../variables.scss';

.BannersMainViewWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  font-size: 14px;
  transform: translate3d(0, 0, 0);

  >div:nth-child(2) {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .BannersViewBody, .BannerSets {
      flex: 1;
      min-height: 0;
    }

    .BannerDetails {
      flex: 1;
      min-height: 0;
    }
  }
}
