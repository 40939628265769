.EditCustodianOfRecordsModal {
  max-height: calc(100vh - 20px);
  width: calc(100vw - 20px);
  max-width: calc(100vw - 20px);

  .EditCustodianOfRecordsModal-inner {
    padding: 20px;
    overflow: auto;
    
    .Field {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .Label {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }

  @media (min-width: 1000px) {
    width: 750px;
  }
}