@import '../../variables';

.BaseCheckboxList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 7px;
  border: 1px solid $grey-lighter; 
  user-select: none;
  padding: 10px;

  .Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid $purple-light;
    margin-bottom: 10px;

    .SelectAll {
      cursor: pointer;
      font-weight: bold;
      margin-left: 10px; 
      padding: 5px 5px 5px 0;
      white-space: nowrap;

      .ant-checkbox {
        margin-right: 5px;

        .ant-checkbox-inner {
          transition: none;

          &::after {
            transition: none;
          }
        }
      }

      &:hover {
        color: $blue-light;
      }
    }

    .Search {
      margin-bottom: 10px;
      max-width: 300px;
    }
  }

  .BaseCheckboxList-inner { 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 15px;
    overflow: auto;
  }

  .BaseCheckboxList-Item {
    cursor: pointer; 
    font-weight: bold; 
    padding: 5px 10px;
    white-space: nowrap;
    text-overflow: ellipsis; 
    overflow: hidden;
    
    .ant-checkbox {
      margin-right: 5px;

      .ant-checkbox-inner {
        transition: none;

        &::after {
          transition: none;
        }
      }
    }

    &:hover {
      color: $blue-light;
      background: rgba(0,0,0, .01);
    }
  }
}