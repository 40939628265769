@import '../../../../../../../../../../../../variables';

.AddMoviesModal {
  width: calc(100vw - 20px);
  height: calc(100vh - 130px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .AddMoviesModal-description {
    margin-bottom: 20px;
  }

  .ValidationMessage {
    color: $invalid-input;
  }

  .idInputContainer {
    div:first-child {
      font-weight: bold;
    }
  }

  .AddMoviesModal-field-wrapper:first-child {
    margin-top: 0;
  }

  .AddMoviesModal-field-wrapper {
    line-height: normal;

    .Label {
      font-weight: bold;
      font-size: 1rem;
    }
  }

  .PlaylistMovieSelect {

    .ant-select {
      width: 100%;
    }
  }

  @media (min-width: 1000px) {
    width: 500px;
    height: calc(90vh - 130px);
    padding: 20px;
  }
}

.AddMoviesModal-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button:last-child:disabled {
    color: $purple-dark !important;
  }
}
