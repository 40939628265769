@import "../../../../../../../../../../../../../variables";

.AdTime {
  padding: 10px;
  border: 1px solid $ant-border;
  border-radius: 5px;
  margin-bottom: 10px;

  .Interval {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .Text {
      font-weight: bold;
    }

    .ant-calendar-picker {
      margin-right: 5px;
    }
  }

  .Interval:last-child {
    margin-bottom: 0;
  }
}