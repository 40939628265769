@import '../../../../../../../../../variables.scss';

.ManufacturerDetails {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  transform: translate3d(0, 0, 0);

  button {
    border-color: $white;
    font-weight: bold;
    margin-right: 5px;
  }

  .ManufacturerDetailsBody {
    flex: 1;
    min-height: 0;
    overflow: auto;
    background-color: $white;
  
    .ManufacturerDetailsBody-inner {
      padding: 20px;
      display: flex;
      flex-direction: column;
    }
  
    @media (min-width: 1000px) {
      .ManufacturerDetailsBody-inner {
        flex-direction: row;
      }
    }
  } 
}
