.UserSettingsModal {
  width: calc(100vw - 20px);
  height: calc(100vh - 130px);
  padding: 10px;
  display: flex;
  flex-direction: column;

  .UserSettingsModal-label {
    font-size: 1rem;
    font-weight: bold;
  }

  .UserSettingsModal-inner {
    margin-top: 5px;
    display: flex;
    flex-direction: column;

    div:first-child {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 1000px) {
    height: 500px;
    width: 500px;
  }
}
