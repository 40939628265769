@import "../../../../../../../../../../../../../../variables";

.PlaylistSceneSelect {
  margin-bottom: 20px;
  
  div:first-child {
    font-weight: bold;
  }
}

.PlaylistSceneSelect-dropdown {

  .PlaylistSceneSelect-dropdown-divider {
    margin: 1px 0;
  }

  .PlaylistSceneSelect-loadMore {
    color: $purple-light;
    padding: 4px 8px;
    cursor: pointer;
  }

  .PlaylistSceneSelect-spinner {
    margin: 4px 10px;
  }
}