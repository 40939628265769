.StudioDetailsBodyLogo {
  margin-right: 0;
  margin-bottom: 20px;
  width: 100%;
}

@media (min-width: 1000px) {
  .StudioDetailsBodyLogo {
    margin-right: 20px;
    margin-bottom: 0;
    width: 300px;
    height: 453px;

    img {
      height: 100% !important;
    }
  }
}
