@import '../../../../../../../../../../variables';

.PropertyDetails {
  h2 {
    margin: 15px 0;
  }
  background-color: $white;
  padding: 10px;
  overflow: auto;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  .TabsComponent {
    flex: 1;
    min-height: 0;

    .ant-tabs-content {
      height: calc(100% - 60px);

      .ant-tabs-tabpane {
        overflow: auto;
      }
    }
  }
}