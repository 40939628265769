@import "../../../../variables";

.SingleFile {

  &.ant-card {
    min-width: 290px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;

    .ant-card-body {
      justify-content: center;
    }

    .SingleFile-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .UploadImages-preview {
        height: 50px;
      }
    }

    .SingleFile-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;

      .anticon {
        font-size: 16px;
        margin-left: 10px;
        margin-bottom: 0;
      }
    }

    .SingleFile-type-select {
      margin-top: 10px;

      .anticon {
        font-size: 16px;
      }
    }

    @media (min-width: 1000px) {

      .SingleFile-wrapper {
        .UploadImages-preview {
          height: 120px;
        }
      }
    }
  }
}
