@import '../../../../../../../../../variables';

.UsersMainView {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  .UsersMainView-inner {
    flex: 1;
    min-height: 0;
    transform: translate3d(0, 0, 0);
    display: flex;
    flex-direction: column;

    > :nth-child(1) {
      flex: 1;
      min-height: 0;
      overflow: auto;

      > :first-child {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    .LoadingIndicator {
      > .ant-spin {
        height: auto;
      }
    }

    .UsersViewBody {
      flex: 1;
      min-height: 0;
    }
  }
}