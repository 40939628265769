.PageInput {
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-input-number-handler-wrap {
    display: none;
  }

  .InvalidInput {
    border: 1px dotted red;
  }

  .anticon {
    font-size: 20px;
  }

  input {
    flex: 1;
    margin-right: 5px;
    max-width: 80px;
  }

  div {
    margin-right: 5px;
  }

  div:last-child {
    margin-right: 10px;
  }

  .PageCount {
    margin-bottom: 4px;
  }
}
