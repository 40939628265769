.ManufacturesMainView {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  transform: translate3d(0, 0, 0);
  padding: 0;

  .ManufacturersViewBody {
    flex: 1;
    min-height: 0;
  }

  .BaseFooter {
    align-items: center;

    .ManufacturesMainView-manufacturersCount {
      font-weight: bold;
    }
  }

  .FiltersHeader {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 15px;
  
    label {
      .user-inputs {
        width: 100vw;
        max-width: 230px;
        margin: 0 8px;
      }
    }
  
    label,
    .clear-filters {
      font-weight: 700;
    }
  }
}
