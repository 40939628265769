@import '../../variables';

.BaseNavigationItem {
  padding: 10px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .Breadcrumb-link,
  .Breadcrumb-text {
    text-transform: uppercase;
    font-weight: bold; 
    display: inline-block;
  }

  .Breadcrumb-text {
    color: $base-navigation-subtitle-color;
  }

  .Breadcrumb-link {
    color: $base-navigation-title-color;

    &:after {
      content: '';
      display: inline-block;
      margin: 0 10px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 16px solid $base-navigation-arrow-color;
    }
  }
}