.EditPropertyModal {
  .EditPropertyModal-inner {
    width: 100vw;
    max-width: 65em;
    height: 75vh;
    padding: 1em;
    overflow: auto;

    .ContentRow {
      .ant-input-number {
        width: 40px;
        height: 25px;

        .ant-input-number-input {
          text-align: center;
          height: 25px;
          padding: 0;
        }

        .ant-input-number-handler-wrap {
          display: none;
        }
      }
    }

    .ContentRow,
    .VariablesContainer li {
      padding: .3em .6em;
      border-bottom: 1px solid #ddd;

      &:hover {
        background-color: rgba(0, 0, 0, .03);
      }
    }

    .ant-collapse-content-box {
      padding: .5em;
    }

    .InputBtnContainer {
      gap: 1em;
      margin: .5em 0;
      padding: .5em;
      background: rgba(0, 0, 0, .03);
      border-radius: .3em;
    }

    .VariablesContainer {
      margin: 0;
      padding: 0;
      list-style: none;

      .fas {
        padding: .3em .6em;

        &:hover {
          opacity: .5;
        }
      }

      .TextNoWrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}