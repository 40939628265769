@import "../../../../../../../../../../../../variables";

.EditMovieSceneModal {
  padding: 20px 10px;
  width: calc(100vw - 20px);
  height: 80vh;
  overflow: auto;

  .text-bold,
  .TimeSelector {
    font-weight: 700;
  }

  .Duration {
    text-align: right;
  }

  .TimeSelector {
    display: unset; 
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .px-10 {
    padding: 0 10px;
  }

  .w-100, 
  .TimeSelector .ant-time-picker {
    width: 100%;
  }

  .CheckboxContainer {
    display: flex;

    label {
      margin-left: 10px;
    }
  } 

  @media (min-width: 1000px) {
    width: 100vw;
    max-width: 1200px;
  }

  .BaseDatePickerList .Inner {
    width: 100%;
  }
}