@import "../../../../../../../../../../variables";

.PayoutsByMonthHeader {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: stretch;
  padding: 0 10px;
  height: auto !important;
  overflow: initial;
  min-height: fit-content;

  .PayoutsByMonthHeader-InnerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;
    padding: 10px 0;
    justify-content: flex-end;

    .Filter-Text {
      margin-right: 5px;
    }

    .anticon {
      display: inline-block;
      font-size: 20px;
      margin-right: 5px;
    }

    @media (min-width: 1050px) {
      .anticon {
        display: none;
      }
    }
  }

  .DateFiltersWrapper {
    display: none;
    margin-bottom: 10px;

    button {
      margin-left: 0;
      margin-top: 10px;
      border-color: $purple-light;
    }

    @media (min-width: 1050px) {
      button {
        margin-left: 10px;
      }
    }

    .DatePickerContainer {
      display: flex;
      align-items: center;

      .MonthPickerField {
        display: flex;
        flex-direction: row;
        align-items: center;

        .Label {
          margin: 0 10px;
        }

        .ant-input {
          margin-right: 10px;
        }
      }
    }
  }

  &.FilterDisplayed {
    .DateFiltersWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    @media (min-width: 1050px) {
      .DateFiltersWrapper {
        flex-direction: row;
      }
    }
  }

  @media (min-width: 1050px) {
    .DateFiltersWrapper {
      display: flex;
      margin-bottom: 0;

      button {
        margin-top: 0;
      }
    }

    flex-direction: column;
  }
}

@media (min-width: 1050px) {
  .PayoutsByMonthHeader {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 10px;

    .Filter-Text {
      display: none;
    }
  }
}
