.NewBannerSetModal {
  .ModalDialogBody {
    width: 500px;
    max-height: 70vh;
    overflow: auto;
    padding: 10px !important;

    .Option {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
    }
  }
}