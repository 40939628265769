@import '../../../../../../../../../../variables.scss';

.PropertiesViewDetailsBody {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;

  > :first-child {
    flex: auto;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }
}
