.LicensorsMainView {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  transform: translate3d(0, 0, 0);
  
  .FiltersHeader {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 15px;
  
    label {
      .user-inputs {
        width: 100vw;
        max-width: 230px;
        margin: 0 8px;
      }
    }
  
    label,
    .clear-filters {
      font-weight: 700;
    }
  }
}
