@import "../../../../../../../../../../../variables";

.MovieFilters {
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    margin: 10px 5px;
  }

  .FilterWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ant-select {
      flex: 1;
      margin-right: 0;
    }

    @media (min-width: 1000px) {
      margin-right: 15px;
    }

    .label {
      font-weight: bold;
      margin-right: 5px;
    }

    .MovieFilterDropdownItemWrapper {
      .MovieFilterDropdownItem {
        display: none;
      }
    }
  }

  .ant-btn {
    font-weight: bold;
    border-color: $purple-light;
  }
}

.MovieFilterDropdownItemWrapper {
  display: flex;
  flex-direction: row;

  .MovieFilterDropdownItemText {
    flex: 1;
  }

  .MovieFilterDropdownItem {
    margin-left: 10px;
    text-decoration: underline;

    &.SelectedSort {
      color: $purple-light;
      font-weight: bold;
    }
  }

  .MovieFilterDropdownItem:hover {
    color: $blue-lighter;
    font-weight: bold;
  }
}
