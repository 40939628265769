@import '../../../../../../../../../../../variables';

.CatalogSelectAbstract {
  margin-top: 1rem;

  .CatalogSelectAbstractBody {
    &.hideClass {
      .ant-table {
        .ant-table-placeholder {
          border-top: none;
        }
        
        .ant-table-body {
          display: none;
        }
      }
    }
  }

  .Label {
    font-weight: bold;
  }

  .CatalogSelectAbstract-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ant-input-affix-wrapper {
      width: max-content;
      
      input {
        float: right;
        max-width: 100px;
      }
    }
  }

  .ant-table-header-column {
    width: 100%;
  }

  .ant-table {
    .ant-table-body {
      background-color: $white;
      overflow: auto;

      table {
        border-radius: 0;

        .ant-table-thead {
          border-radius: 0;

          tr:first-child {
            th:first-child {
              border-top-left-radius: 0;
            }

            th:last-child {
              border-top-right-radius: 0;
            }
          }
        }
      }
    }
  }

  .BaseCheckboxList-inner {
    display: initial;
    column-count: 5;
    max-height: 400px; 
  }
}