.SceneDetailsInfoTab {
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  .SceneDetailsInfoTab-Images {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .ant-table-wrapper {
    margin-bottom: 16px;
    width: 100%;
  }
  
  @media (min-width: 1000px) {
    .SceneDetailsInfoTab-Images {
      margin-right: 20px;
      margin-bottom: 0;
      height: 270px;
      width: auto;

      img {
        height: 100% !important;
      }
    }
  }
}

@media (min-width: 1000px) {
  .SceneDetailsInfoTab {
    flex-direction: row;
  }
}