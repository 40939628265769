.SceneDetailsSampleVideoTab {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 10px;

  .recreateBtn {
    margin: 10px;
  }

  .FullPlayer {
    min-height: 300px;
    height: 50vh;
    width: auto;
  }

  .BaseImagesDisplay {
    flex: 1;
    min-height: 0;
  }
}