@import '../../../../../../../../../../../variables';

.MoviePlaylistDetails {
  flex: auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  .MoviePlaylistDetails-body {
    background-color: $white;
    padding: 0 10px;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .TabsComponent {
      flex: 1;
      min-height: 0;

      .ant-tabs-content {
        height: calc(100% - 60px);

        .ant-tabs-tabpane {
          overflow: auto;
        }
      }
    }
  }
}