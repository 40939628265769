.ImageComponent {
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.is-custom {
    border: 2px solid #c1121f;
  }

  &.is-alt {
    border: 2px solid #0278c4;
  }

  &.is-cover {
    border: 2px solid #e09f3e;
    background-color: #e09f3e;
  }

  .imageloader {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto !important;
    }

    .ImageComponent-notFound {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}
