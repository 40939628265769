.TimeSelector {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > div:first-child {
    margin-right: 0;
  }

  .ant-time-picker {
    width: auto;
  }
  
  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: center;

    > div:first-child {
      margin-right: 10px;
    }
  }
}
