@import '../../../../../../../../../../variables';

.StudiosViewHeader {
  padding: 10px;
  display: flex; 

  .ExportBtn {
    margin-left: 10px;
    border-color: $purple-light;
  }
  .StudioFilters {
    display: flex;
    flex-direction: row;
    align-items: center;

    .FilterWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      .ant-select {
        flex: 1;
        margin-right: 0;
      }

      @media (min-width: 1000px) {
        margin-right: 10px;
      }

      .label {
        font-weight: bold;
        margin-right: 5px;
      }

      .StudioFilterDropdownItemWrapper {
        .StudioFilterDropdownItem {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1000px) {
    .StudioFilters {
      display: flex; 

      .ant-select-selection,
      input {
        width: 100vw;
        max-width: 230px;
      } 
    }

    flex-direction: column;
  }
}

.StudioFilterDropdownItemWrapper {
  display: flex;
  flex-direction: row;

  .StudioFilterDropdownItemText {
    flex: 1;
  }

  .StudioFilterDropdownItem {
    margin-left: 10px;
    text-decoration: underline;

    &.SelectedSort {
      color: $purple-light;
      font-weight: bold;
    }
  }

  .StudioFilterDropdownItem:hover {
    color: $blue-lighter;
    font-weight: bold;
  }
}
