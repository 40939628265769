.ConfigureAppAds {
  width: 500px;

  .ConfigureAppAds-inner {
    padding: 10px 10px 40px 10px;

    .Description {
      margin-bottom: 40px;
    }

    .ant-radio-wrapper {
      display: block;
      margin-bottom: 5px;
    }
  }
}