.HomePageSpotlightModal {
	width: 500px;
	min-height: 500px;

	.ModalDialogBody {
		padding: 10px !important;
	}

	.HomePageSpotlight-actions {
		display: flex;
		flex: 1;
		justify-content: flex-end;

		.saveChangesBtn {
			margin-left: unset;
			margin-right: auto;
		}
	}

	.Option {
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;

		&:last-child {
			margin-bottom: unset;
		}
	}
}
