@import '../../../variables';

.BaseImagesDisplayList {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  .ant-card {
    margin-bottom: 10px;
    position: relative;

    .ImageComponent  {
      margin: auto;
    }

    .ImageActions {
      text-align: center;
    }

    &:hover {
      .BaseImagesDisplayList-actions {
        display: flex;
        cursor: pointer;
      }
    }

    .BaseImagesDisplayList-actions {
      display: none;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 10px;

      .BaseImagesDisplayList-overlay {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        min-width: 0;
        background-color: $grey-light;
        opacity: .8;
      }
    }
  }
}