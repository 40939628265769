@import '../../variables';

.BaseDatePickerList {
    border: 1px solid #c0c1f5;
    border-radius: 7px;
    padding: 10px 15px;

    .Header {
        border-bottom: 2px solid #7a7de5;
        margin-bottom: 10px;
        font-weight: bold;
        padding: 5px 0;

        .DatePicker {
            margin-left: 10px;
            min-width: 215px !important;
        }
    }

    .Inner {
        display: grid;
        grid-template-columns: repeat(3, minmax(375px, 1fr));
        gap: 0 15px;
        overflow: auto;
        width: calc(100vw - 400px);

        .ListItem {
            display: flex;
            align-items: center;
            font-weight: 700;
            padding: 4px 0;
            border-bottom: 1px solid rgba(0, 0, 0, .1);

            .Title {
                margin: 0 10px 0 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .Title,
            .DatePickerContainer {
                flex: 1;
            }

            .RecurrenceIcon {
                font-size: 1.6em;
                margin: 0 0.3em 0 0;
                cursor: pointer;

                &.Active {
                    color: #ff3cac;
                }

                &:hover {
                    opacity: .5;
                }
            }

            &:hover {
                background: rgba(0, 0, 0, .01);
            }
        }
    }

    .DatePicker {
        flex: 1;

        input:not([value='']) {
            font-weight: 700;
            letter-spacing: -.6px;
            padding: 1px 3px;
        }
    }
}