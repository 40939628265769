.BaseImagesDisplay {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  min-height: 0;

  .OptionsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 10px;

    .BaseImagesDisplaySearch {
      margin-top: 10px;
      margin-right: 10px;
    }

    .DisplayType {
      margin: 10px 0 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      .Label {
        font-weight: bold;
        white-space: nowrap;
      }

      .ant-select {
        flex: 1;
        min-width: 0;
        margin-left: 10px;
      }
    }
  }

  .BaseImagesGridDisplay {
    flex: 1;
    min-height: 0;
  }

  .BaseImagesMosaicDisplay {
    flex: 1;
    min-height: 0;
  }

  .ImagesGridDisplay {
    flex: 1;
    min-height: 0;
    overflow: auto;
  }

  @media (min-width: 1000px) {
    .OptionsContainer {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .BaseImagesDisplaySearch {
        margin-top: 0;
      }

      .DisplayType {
        margin-left: 10px;
        margin-right: 10px;

        .ant-select {
          width: 300px;
        }
      }
    }
  }
}