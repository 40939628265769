@import "../../../../../../../../../../../variables";

.InvalidReasonDisplay {
  padding: 10px;
  margin: 10px;
  font-weight: bold;
  border: 1px solid $purple-dark;
  border-radius: 8px;

  .Title {
    font-size: 20px;
  }
}