@import "../../../../../../../../../variables";

.PropertiesMainView {
  flex: 1; 
  display: flex; 
  flex-direction: column;

  .PropertiesBody {
    flex: 1;
  }

  .Header {
    display: flex; 
    justify-content: flex-end;
    padding: 5px 15px;
  
    .InputWrapper {
      display: flex; 
      align-items: center; 

      .ant-select {
        width: 100vw;
        max-width: 200px;
        margin: 0 5px;
      }
    } 
  }

  .BaseFooter {

    button {
      border-color: $purple-light;
      font-weight: bold;
      margin-right: 10px;
    }
  }
}
