@import '../../../../../../../../../../../variables';

.StarDetailsBodyTable {
  &.ant-table-wrapper {
    flex: 1;
    min-width: 0;

    .ExclusiveStar-Container {
      display: flex;
      align-items: center;
      height: 32px;

      button {
        margin-left: 10px;
      }
    }

    .ImageComponent {
      height: 100%;
      width: 150px;
    }

    td:first-child {
      font-weight: bold;
    }

    .StatusItem {
      font-weight: bold;
      font-size: 1rem;
      padding: 5px;

      &.StarEnabled {
        background-color: $studio-enabled;
        color: $white;
      }

      &.StarDisabled {
        background-color: $studio-disabled;
        color: $white;
      }
    }

    .ant-table-footer {

      .UserActions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .ant-btn {
          border-color: $rebeca-purple;
          width: fit-content;
          font-size: 20px;

          &:hover {
            color: $rebeca-purple;
          }
        }
      }
    }

    @media (min-width: 1000px) {
      .ImageComponent {
        width: 300px;
      }
    }
  }
}
