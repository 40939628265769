@import "../../../../../../../../../../../variables";

.StudioDetailsBody {
  flex: 1;
  min-height: 0;
  display: flex;
  padding: 0 10px;
  background-color: $white;

  .TabsComponent {
    width: 100%;
    padding-bottom: 20px;
    
    .ant-tabs-bar {
      margin-bottom: 0;
    }

    .ant-tabs-tabpane {
      overflow: auto;
    }

    .StudioDetailsBody-inner {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
    }

    .StudioDetailsBodyImages {
      height: 100%;
    }
  }

  @media (min-width: 1000px) {
    .TabsComponent {
      .StudioDetailsBody-inner {
        flex-direction: row;
      }
    }
  }
}
