@import "../../../../../../../../../../../../variables";
@import "../../../shared/ValidationMixin";

.AdsConfigurationModal {
  width: calc(100vw - 100px);
  height: calc(100vh - 60px);
  max-width: 600px;
  max-height: 900px;

  .AdsConfigurationModal-inner {
    padding: 10px;
    overflow: auto;

    .AdConfigCollapsePanel {

      > .ant-collapse-header {
        font-weight: bold;

        .AdConfigHeader {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .ant-btn {

            .ant-checkbox-wrapper {
              margin-left: 10px;
            }
          }

          .anticon {
            margin-left: 10px;
          }
        }
      }

      > .ant-collapse-content {

        > .ant-collapse-content-box {
          padding: 0;

          .AdConfigPanel {
            border: none;

            .ant-card-head {
              display: none;
            }
          }
        }
      }

      &.Valid {

        > .ant-collapse-header {
          @include Valid;
        }
      }

      &.Invalid {
        > .ant-collapse-header {
          @include Invalid;
        }
      }
    }
  }

  .Actions {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}