@import "../../../variables";

.MainModalDialog {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100vw - 20px);

  .ModalDialogHeader {
    cursor: pointer;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: $white;
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    font-weight: 500;
    font-size: 16px;
    border-bottom: 1px solid $ant-border;
    zoom: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $header-gradient-primary;
    background-image: linear-gradient(225deg, $header-gradient-primary 0%, $header-gradient-secondary 50%, $header-gradient-ternary 100%);


    .ModalDialogTitle {
      display: flex;
      flex: 1;
      min-width: 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .ModalDialogTitle-actions {
        display: flex;
        flex-direction: row;

        .anticon-arrows-alt {
          display: none;
        }

        .anticon {
          cursor: pointer;
          margin-left: 5px;
        }

        @media (min-width: 1000px) {
          .anticon-arrows-alt {
            display: inline-block;
          }
        }
      }
    }
  }

  > .ant-card-bordered {
    border-radius: 0 0 10px 10px;
    border: 1px solid $purple-light;
    flex: 1;
    min-height: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    .ant-card-body {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      padding: 0;

      .ModalDialogBody {
        display: flex;
        flex-direction: column;
        padding: 0;
        flex: 1;
        min-height: 0;
        align-items: stretch;
      }

      .ModalDialogFooter {
        border-top: 1px solid $purple-light;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button {
          margin-left: 10px;
          border-color: $purple-light;
        }

        button:last-child {
          background-color: $purple-light;
          color: $white;
        }
      }
    }
  }

  @media (min-width: 1000px) {
    width: auto;
  }
}
