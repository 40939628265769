@import "../../../../../../../../../../variables";

.ChangePasswordModal {
  width: calc(100vw - 20px);
  padding: 10px;
  display: flex;
  flex-direction: column;

  .ChangePasswordModal-field-wrapper {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .Label {
      font-weight: bold;
    }
  }

  @media (min-width: 1000px) {
    width: 400px;
  }
}

.ChangePasswordModal-actions {
  button:last-child:disabled {
    color: $purple-dark !important;
  }
}
