@import '../../variables';

.BaseLoadMoreSelect {
  .ant-select {
    width: 100%;
  }
}

.BaseLoadMoreSelect-dropdown {
  .BaseLoadMoreSelect-dropdown-divider {
    margin: 1px 0;
  }

  .BaseLoadMoreSelect-loadMore {
    color: $purple-light;
    padding: 4px 8px;
    cursor: pointer;
  }

  .BaseLoadMoreSelect-spinner {
    margin: 4px 10px;
  }
}