@import '../../../../../../../../../../../../variables';

.EditScenePlaylistModal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100vw - 20px);
  height: 500px;
  overflow: auto;

  .EditScenePlaylistModal-inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    
    .EditScenePlaylistModal-inner-label {
      font-weight: bold;
      font-size: 1rem;
    }

    .ant-input-number {
      width: 100%;
    }
  }

  @media (min-width: 1000px) {
    width: 500px;
  }
}

.EditScenePlaylistModal-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button:last-child:disabled {
    color: $purple-dark !important;
  }
}