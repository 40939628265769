@import "../../../../../../../../../../../../../variables";

.AdConfigPanel {
	padding: 10px;
	border-radius: 5px;

	.AdUserStateConfig {
		padding: 10px;
		border: 1px solid $ant-border;
		border-radius: 5px;
		margin-bottom: 10px;

		.UserStateManagement {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;

			.Text {
				font-weight: bold;
			}
		}

		.UserStateManagement:last-child {
			margin-bottom: 0;
		}
	}

	.AdTargetSelection {
		padding: 10px;
		border: 1px solid $ant-border;
		border-radius: 5px;
		margin-bottom: 10px;

		.ant-radio-group {
			width: 100%;

			.SectionOptions {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 10px;

				.Text {
					font-weight: bold;
				}

				.ant-radio-wrapper {
					display: block;
					margin-right: 0;
				}
			}

			.SectionOptions:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.ant-card-bordered {
		border: 1px solid $purple-light;
	}

	.ant-card-head {
		margin-right: 1px;
		margin-left: 1px;
		margin-top: 1px;

		.AddHeader {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}
}
