@import "../../../../../../../../variables";

.CategoriesView {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  > :first-child {
    flex: auto;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }
}