@import "../../../../../../../../../variables";

.CategoriesMainView {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;


  .StatusItem {
    font-weight: bold;
    font-size: 1rem;
    padding: 5px;

    .ThemeEnabled {
      background-color: $studio-enabled;
      color: $white;
    }

    .ThemeDisabled {
      background-color: $studio-disabled;
      color: $white;
    }
  }

  .ant-btn {
    border-color: $purple-light;
    margin-left: 5px;
  }

  .CategoriesMainView-categoryTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .ant-list {
    display: block;
    flex: 1;
    min-height: 0;
    overflow: auto;
    background-color: $white;
    padding: 0 10px;
  }

  .ant-card {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ant-table-wrapper {
    background-color: $white;
    display: none;
    flex-direction: column;
    flex: 1;
    min-height: 0;

    .Actions-Container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    div {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
    }

    .ant-table-header {
      flex: none;
    }

    table {
      td:first-child {
        font-weight: bold;
      }
    }
  }

  @media (min-width: 1000px) {
    .ant-list {
      display: none;
    }

    .ant-table-wrapper {
      display: flex;

      .Actions-Container {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}

.ThemeCard-enabledDisabled {

  &.ant-dropdown-menu {
    padding: 0 2px 2px 2px;

    li:first-child {
      background-color: $studio-enabled;
      color: $white;
    }

    li:first-child:hover {
      font-weight: bold;
    }

    li:last-child {
      background-color: $studio-disabled;
      color: $white;
    }

    li:last-child:hover {
      font-weight: bold;
    }
  }
}
