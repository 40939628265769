@import '../../../../../../../../../variables';

.AddLicensorModal {
  width: calc(100vw - 20px);
  height: calc(100vh - 130px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  
  .AddLicensorModal-field-wrapper:first-child {
    margin-top: 0;
  }

  .AddLicensorModal-field-wrapper {
    line-height: normal;
    margin-top: 15px;

    .Label {
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 3px;
    }

    .ant-input-number {
      width: 100%;
    }
  }

  @media (min-width: 1000px) {
    width: 500px;
    height: 80vh;
    padding: 20px;
  }
}
.AddLicensorModal-actions {
  button:last-child:disabled {
    color: $purple-dark !important;
  }
}
