.BaseImagesDisplaySearch {
  display: flex;
  flex-direction: row;
  align-items: center;

  div:first-child {
    font-weight: bold;
    white-space: nowrap;
  }

  .ant-input {
    margin-left: 10px;
  }
}