@import "../../../../../../../../../../variables";

.MovieDetailsImagesTab {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .MovieDetailsImagesTab-inner {
    display: flex;
    flex-direction: row;
    flex: 1;
    min-width: 0;
    flex-wrap: wrap;
    justify-content: center;

    .ImagesGridDisplay-card-image {
      width: 100% !important;
    }
  }

  @media (min-width: 500px) {

    .MovieDetailsImagesTab-card {
      margin-right: 20px;
    }
  }
}
