@import '../../../../../../../../../../variables';

.StarDetailsBody {
  flex: 1;
  min-height: 0;
  display: flex;
  padding: 0 10px;
  background-color: $white;
  width: 100%;

  .TabsComponent {
    padding-bottom: 20px;
    width: 100%;

    .ant-tabs-bar {
      margin-bottom: 0;
    }

    .ant-tabs-tabpane {
      overflow: auto;
    }

    .StarDetailsBodyDescription {
      margin-top: 10px;
    }

    .StarDetailsBody-inner {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
    }

    .ImagesTab {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .StarDetailsBodyImages {
        flex: 1;
        min-height: 0;
      }
    }
  }

  @media (min-width: 1000px) {
    .StarDetailsBody-inner {
      flex-direction: row;
    }
  }
}
