@import "../../../../../../../../../../variables";

.PlayerConfigurationCard {

  &.BaseCard {
    border-color: $purple-dark;

    .ant-card-head {
      margin-top: 1px;
      margin-right: 1px;
      margin-left: 1px;

      .Title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .BaseCard-body-children {
      padding: 0;
    }

    .Actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .ant-btn {
        margin-left: 10px;
        border-color: $purple-dark;
        font-weight: bold;
      }
    }
  }
}