@import "../../../../../../../../../../variables";

.ManufacturersViewBody {
  display: flex;
  flex-direction: column;
  background-color: $white;

  .ant-table-wrapper {
    display: none;

    div:first-child {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
    }

    .ant-table {

      .ant-table-body {
        background-color: $white;

        table {
          border-radius: 0;

          .ant-table-thead {
            border-radius: 0;

            tr:first-child {
              th:first-child {
                border-top-left-radius: 0;
              }

              th:last-child {
                border-top-right-radius: 0;
              }
            }
          }
        }
      }
    }
  }

  .ant-list {
    flex: 1;
    min-height: 0;
    display: block;
    overflow: auto;
    padding: 10px;

    .ant-list-item {
      padding: 0;
      margin-bottom: 10px;

      .ManufacturersViewBody-listItem-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    .ant-list-item:last-child {
      margin-bottom: 0;
    }
  }

  .EditButton {
    border-color: $purple-light;
    margin-left: 5px;
  }

  @media (min-width: 1000px) {
    .ant-table-wrapper {
      display: flex;
      flex: 1;
      min-height: 0;
    }

    .ant-list {
      display: none;
    }
  }
}
