.TestAdConfigModal {
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  max-height: 700px;
  max-width: 700px;

  .TestAdConfigModal-inner {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .ImageAd {
      flex: 1;
      min-height: 0;
    }
  }
}