@import "../../../../../../../../../../variables.scss";


.NatsUsersViewHeader {
  .NatsUsersViewHeader-inner {
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: $white-grey;

    .NatsUsersViewHeader-mainWrapper {
      display: none;
      flex-direction: column;
      align-items: stretch;

      &.FiltersToggled {
        display: flex;
      }

      .NatsUsersViewHeader-filterLabel {
        margin-right: 10px;
        font-weight: bold;
      }

      .NatsUsersViewHeader-filterWrapper:first-child {
        flex: 1;
      }

      .NatsUsersViewHeader-filterWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .ant-select {
          min-width: 300px;
        }

        input {
          flex: 1;
        }
      }

      .NatsUsersViewHeader-filterWrapper:nth-child(2) {
        margin-left: 0;
        margin-bottom: 10px;
        min-width: 300px;
      }

      button {
        margin-left: 0;
      }
    }

    .NatsUsersViewHeader-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      padding-right: 5px;

      .anticon {
        margin-left: 20px;
      }
    }

    @media (min-width: 1000px) {
      flex-direction: row;

      .NatsUsersViewHeader-mainWrapper {
        flex: 1;
        min-width: 0;
        display: flex;
        flex-direction: row;

        .NatsUsersViewHeader-filterWrapper {
          flex-direction: row;
          align-items: center;
        }

        .NatsUsersViewHeader-filterWrapper:nth-child(2) {
          margin-left: 10px;
          margin-bottom: 0;
        }

        button {
          margin-left: 10px;
        }
      }

      .NatsUsersViewHeader-inner {
        display: none;
        margin-bottom: 0;
      }
    }
  }
}
