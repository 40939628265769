@import "../../../../../../../../../../../../variables";

.AdConfigPanel {
	margin-bottom: 10px;
	border-color: $purple-dark;

	.ant-card-head {
		margin-left: 1px;
		margin-top: 1px;
	}

	.AdCardTitle {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.AdConfigPanel-inner {
		.AdUserStateConfig {
			padding: 10px;
			border: 1px solid $ant-border;
			border-radius: 5px;
			margin-bottom: 10px;

			.UserStateManagement {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 10px;

				.Text {
					font-weight: bold;
				}
			}

			.UserStateManagement:last-child {
				margin-bottom: 0;
			}
		}

		.AdSelectedSectionConfig {
			padding: 10px;
			border: 1px solid $ant-border;
			border-radius: 5px;
			margin-bottom: 10px;

			.ant-radio-group {
				width: 100%;

				.SectionOptions {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 10px;

					.Text {
						font-weight: bold;
					}

					.ant-radio-wrapper {
						display: block;
						margin-right: 0;
					}
				}

				.SectionOptions:last-child {
					margin-bottom: 0;
				}
			}
		}

		.AdTargetSelection {
			padding: 10px;
			border: 1px solid $ant-border;
			border-radius: 5px;
			margin-bottom: 10px;

			.ant-radio-group {
				width: 100%;

				.SectionOptions {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 10px;

					.Text {
						font-weight: bold;
					}

					.ant-radio-wrapper {
						display: block;
						margin-right: 0;
					}
				}

				.SectionOptions:last-child {
					margin-bottom: 0;
				}
			}
		}

		.AdSelectedApplicationTimeConfig {
			padding: 10px;
			border: 1px solid $ant-border;
			border-radius: 5px;
			margin-bottom: 10px;

			.ant-radio-group {
				width: 100%;

				.SectionOptions {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 10px;

					.Text {
						font-weight: bold;
					}
				}

				.SectionOptions:last-child {
					margin-bottom: 0;
				}
			}
		}

		.AdConfiguration {
			.AddConfigurationHeader {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				.Text {
					font-weight: bold;
				}

				.ant-btn {
					border-color: $purple-light;
					background-color: $purple-light;
					color: $white;
				}
			}

			.AdConfigurationPanel {
				padding: 10px;
				border: 1px solid $ant-border;
				border-radius: 5px;

				.AdStartTime {
					display: none;
				}
			}
		}
	}
}
