.CopyCatalogModal {
    width: 100vw;
    max-width: 40em;
    min-height: 130px;
    padding: 2em .5em;
    text-align: center;

    .BaseDropdown {  
        margin: 0 .5em;
    }

    .BaseDropdown,
    .Label {
        font-weight: 600;
    }
}