@import "../../../../../../../../../../variables";

.MovieDetailsMainView {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  .MovieDetails-body {
    padding: 10px;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    background-color: $white;

    .TabsComponent {
      flex: 1;
      min-height: 0;

      .ant-tabs-content {
        height: calc(100% - 60px);

        .ant-tabs-tabpane {
          overflow: auto;

          .MovieDetailsImagesTab {
            height: 100%;
          }

          .MovieDetailsPlayerTab {
            height: 100%;
          }
        }
      }
    }
  }
}