.BaseDragDropList {
  overflow: auto;

  .BaseDragDropList-item {
    display: flex;
    flex-direction: column;

    .DraggableItem {
      cursor: move;
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }
  }
}