@import '../../../../../../../../../variables';

.RoyaltiesUsersView {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 0;
  transform: translate3d(0, 0, 0);

  .RoyaltiesUsersViewWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    transform: translate3d(0, 0, 0);
    overflow: auto;

    > :first-child {
      flex: 1 1;
      min-height: 0;
      display: flex;
      flex-direction: column;

      .NatsUserDetails {
        flex: 1 1;
        min-height: 0;
      }
    }

    .LoadingIndicator {
      > .ant-spin {
        height: auto;
      }
    }
  }
}