@import '../../../../../../../../../variables';

.EditStudioModal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100vw - 20px);
  height: calc(100vh - 110px);
  overflow: auto;

  .EditStudioModal-inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .EditStudioModal-inner-label {
      font-weight: bold;
      font-size: 1rem;

      label {
        margin-left: 8px;
      }
    }

    .ant-input-number {
      width: 100%;
    }
  }

  @media (min-width: 1000px) {
    width: 1000px;
    height: auto;
    max-height: calc(100vh - 110px);
  }
}
