@import "../../../../../../../../../../../../../variables";

.StudioDetailsBodyTable {
  &.ant-table-wrapper {
    flex: 1;
    min-width: 0;

    tr {
      height: 76px;
    }

    td:first-child {
      font-weight: bold;
      font-size: 16px;
    }

    .Catalog-Container {
      display: inline-block;

      >span:nth-child(2) {
        padding-right: 5px;
      }

      .Movie-Catalog {
        color: $link-color;
        cursor: pointer;
      } 
    }

    .StatusItem {
      font-weight: bold;
      font-size: 1rem;
      padding: 5px;

      .StudioEnabled {
        background-color: $studio-enabled;
        color: $white;
      }

      .StudioDisabled {
        background-color: $studio-disabled;
        color: $white;
      }
    }

    .Status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: fit-content;
      font-weight: bold;
      padding: 5px 10px;
      border-radius: 4px;

      &.StudioEnabled {
        background-color: $studio-enabled;
        color: $white;
      }

      &.StudioDisabled {
        background-color: $studio-disabled;
        color: $white;
      }
    }

    .UserActions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .ant-btn {
        border-color: $purple-light;
        margin-left: 5px;
      }
    }
  }
}
