.BaseImagesGridDisplay {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .BaseImagesFullScreen {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    min-height: 0;

    .BaseImagesDisplayTable {
      flex: 1;
      min-height: 0;
      display: none;
    }

    .BaseImagesDisplayList {
      flex: 1;
      min-height: 0;
      display: flex;
    }

    @media (min-width: 1000px) {
      .BaseImagesDisplayTable {
        display: flex;
      }

      .BaseImagesDisplayList {
        display: none;
      }
    }
  }
}