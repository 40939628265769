@import "../../../../../../variables";

.MainDashboardSideMenu {

  &.ant-layout-sider {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 100;
    background: $side-menu-gradient-primary; /* fallback for old browsers */
    background: -webkit-linear-gradient($side-menu-gradient-ternary, $side-menu-gradient-secondary, $side-menu-gradient-primary); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient($side-menu-gradient-ternary, $side-menu-gradient-secondary, $side-menu-gradient-primary); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    .ant-layout-sider-children {
      display: flex;
      display: -ms-flexbox;
      flex-direction: column;
      flex: 1;
      min-height: 0;
      background: transparent;

      ul {
        flex: 1;
        -ms-flex: 1;
        min-height: 0;
        border-right: 0;
        background: transparent;

        &.ant-menu:not(.ant-menu-horizontal) {

          li {
            a {
              color: $grey-light;
              font-weight: bold;
            }

            &.ant-menu-item-selected {
              background-color: $purple-dark;
              width: 100%;

              a {
                color: $white;
              }

              &:after {
                display: none;
              }

              &:before {
                border-left: 3px solid $purple-light;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }

  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
}
