.MainDialog {
  height: calc(100vh - 40px);
  width: calc(100vw - 40px);
  max-width: 1000px;
  max-height: 900px;

  .MainDialog-inner {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    overflow: auto;

    .NoProperties {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .Actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    min-width: 0;
  }
}