@import '../../../../../../../../../variables';

.PayoutsByMovie {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  height: 100%;
  transform: translate3d(0, 0, 0);

  .PayoutsByMovieMainView {
    flex: 1;
    min-height: 0;
  }
}