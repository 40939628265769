@import '../../../../../../../../../../variables.scss';

.MoviesViewFooter-maxView {
  display: none;

  button {
    margin-right: 10px;
    border-color: $purple-light;
    font-weight: bold;
  }
}

.MoviesViewFooter-minView {
  button {
    border-color: $purple-light;
    font-weight: bold;
  }
}

@media (min-width: 1000px) {

  .MoviesViewFooter-maxView {
    display: flex;
  }

  .MoviesViewFooter-minView {
    display: none;
  }
}

.MoviesViewFooter-dropdownMenu {
  .anticon {
    margin-right: 5px;
  }
}
