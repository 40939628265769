@import "../../../../../../../../../../variables";

.MoviesViewHeader {
  display: flex;  
  padding: 10px; 
  justify-content: space-between;

  .StatusInnerWrapper { 
    .anticon {
      display: inline-block;
      font-size: 20px;
      margin-right: 5px;
    } 

    @media (min-width: 1000px) {
      .anticon {
        display: none;
      }
    }
  } 

  &.FilterDisplayed {
    .MovieFilters {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .FilterWrapper {
        margin-bottom: 3px;
        flex-direction: column;
        align-items: stretch;

        .label {
          margin-bottom: 5px;
        }
      }

      .ClearFilter {
        margin-top: 15px;
      }
    }
  }

  @media (min-width: 1000px) { 
    .ant-select-selection,
    input {
      width: 100vw;
      max-width: 180px;
    }  
  }
} 