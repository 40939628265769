@import '../../../../../../../../variables';

.PayoutsView {
  flex: 1 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  transform: translate3d(0, 0, 0);

  > :first-child {
    height: 100%;
    overflow: auto;
  }

  > :nth-child(2) {
    flex: auto;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  .BaseNavigationItem {

    .ant-btn {
      border-color: $purple-light;
      font-weight: bold;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  table {
    background-color: #fff;
  }
}
