@import "../../../../../../../../../../variables";

.SelectSubCategoriesModal {
  max-height: calc(100vh - 130px);
  width: 100%;
  padding: 20px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;

  .SubCategoriesBox {
    height: 300px; 
  }

  .SelectSubCategoriesModal-label {
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .ant-select {
    margin-bottom: 15px;
  }
  
  @media (min-width: 1000px) {
    width: 700px;
  }
}

.SelectSubCategoriesModal-actions {
  button:last-child:disabled {
    color: $purple-dark !important;
  }
}
