.EditBannedWordsModal {
    height: 80vh;
    padding: 20px;
    min-width: 450px;
    display: flex;
    min-height: 250px;
    flex-direction: column;
    overflow-y: scroll;

    .Sample {
        max-height: 50px;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-around;
        padding: 5px;
        align-items: center;

        .Column {
            flex-direction: column;
            padding: 10px;

            .error {
                border: 1px solid red;
                border-radius: 5px;
            }
        }
    }
}
