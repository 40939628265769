.MFIDsView {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  > :first-child {
    flex: auto;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }
}
