@import "../../../../../../../../../../variables";

.HostsTab {

  .HostsTab-List {
    display: block;
    .card-footer {
      display: flex;
      justify-content: flex-end;

      button {
        border-color: $purple-light;
      }
    }
  }

  .ant-table {
    display: none;
  }

  @media (min-width: 1000px) {
    .HostsTab-List {
      display: none;
    }

    .ant-table {
      display: block;
    }
  }
}
