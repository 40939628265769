@import "../../../../../../../../../../variables";

.EditBannerModal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100vw - 20px);
  height: auto;
  max-height: calc(100vh - 130px);
  overflow: auto;

  .ImageComponent {
    height: 100%;
    width: 100%;
    margin-bottom: 20px;

    .imageloader {
      align-items: stretch;
    }
  }

  .EditBannerModal-inner {
    display: flex;
    flex-direction: column;
    margin-bottom: .4em;

    .EditBannerModal-inner-label {
      font-weight: bold;
      font-size: 1rem;
    }
  } 

  @media (min-width: 1000px) {
    width: 1000px;
  }
}

.EditBannerModal-multiSelectDropdown {
  .ant-select-dropdown-menu-item-selected {
    background-color: $blue-lighter;
  }
}
