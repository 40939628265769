.EditLicensorModal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100vw - 20px);
  height: 80vh;
  overflow: auto;

  .EditLicensorModal-inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .EditLicensorModal-inner-label {
      font-weight: bold;
      font-size: 1rem;
    }
  }

  @media (min-width: 1000px) {
    width: 500px;
  }
}
