.SingleCustodianOfRecords {

  .Field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .Label {
      font-weight: bold;
      font-size: 1rem;
    }
  }
}