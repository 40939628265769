@import "../../../../../../../../../../variables";

.PropertyDetailsBannerSets {
  height: 100%;
  transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
  background-color: $white;

  > div {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  .PropertyDetailsBannerSetsList {
    flex: 1;
    min-height: 0;
  }

  .BannerSetBannersAbstract {
    flex: 1;
    min-height: 0;
  }
}