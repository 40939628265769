@import "../../../../../../../../../../variables";

.UserInfo {
  overflow: auto;
  height: 100%;

  .BaseCard {
    margin-bottom: 20px;

    .ant-card-head {
      color: $purple-light;

      .ant-card-head-title {
        font-weight: bold;
        font-size: 1rem;
      }
    }

    .ant-card-body {
      padding: 0;

      .BaseCard-body-children {
        padding: 0;

        .StatusItem {
          font-weight: bold;
          font-size: 1rem;
          padding: 5px;

          .UserEnabled {
            background-color: $user-enabled;
            color: $white;
          }

          .UserDisabled {
            background-color: $user-disabled;
            color: $white;
          }
        }

        .ant-table {

          table {
            border-left-width: 0;

            tr {
              th:last-child {
                border-right-width: 0;
              }

              td:first-child {
                border-left-width: 0;
              }

              td:last-child {
                border-right-width: 0;
              }
            }

            tr:last-child {
              td {
                border-bottom-width: 0;
              }
            }
          }
        }

        .ant-tag {
          font-weight: bold;
          font-size: 1rem;
          padding-bottom: 2px;
        }

        @media (min-width: 1000px) {
          .ant-table-wrapper:first-child {
            display: block;
          }
        }
      }
    }
  }


  .BaseCard:first-child {

    td:first-child {
      font-weight: bold;
    }
  }

  .BaseCard:nth-child(2) {
    .ant-table-wrapper:first-child {
      display: none;
    }

    .ant-table-wrapper:nth-child(2) {

      td:first-child {
        font-weight: bold;
      }
    }
  }

  @media (min-width: 1000px) {
    .BaseCard:nth-child(2) {
      .ant-table-wrapper:first-child {
        display: block;
      }

      .ant-table-wrapper:nth-child(2) {
        display: none;
      }
    }
  }
}

.UserInfo-enabledDisabled {

  &.ant-dropdown-menu {
    padding: 0 2px 2px 2px;

    li:first-child {
      background-color: $user-enabled;
      color: $white;
    }

    li:first-child:hover {
      font-weight: bold;
    }

    li:last-child {
      background-color: $user-disabled;
      color: $white;
    }

    li:last-child:hover {
      font-weight: bold;
    }
  }
}
