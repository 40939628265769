@import '../../../../../../../../../../variables.scss';

.BannersViewHeader {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .BannersViewHeader-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    .BannerFilters {
      display: none;
    }

    .BannersViewHeader-primary {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .RecordCountMessage {
        margin-right: 5px;
        font-weight: bold;
      }

      .BannersViewHeader-toggle {
        font-size: 18px;
        margin-right: 5px;
        padding-top: 5px;
      }
    }
  }

  .BaseHorizontalNavigation-actions {
    .ant-btn {
      font-weight: bold;
      margin-right: 5px;
      border-color: $purple-light;
    }
  }

  &.FiltersDisplayed {
    .BannersViewHeader-inner {
      .BannerFilters {
        display: flex;
      }
    }
  }

  @media (min-width: 1000px) {

    .BannersViewHeader-toggle {
      display: none;
    }

    .BannersViewHeader-inner {
      .BannerFilters {
        display: flex;
      }
    }
  }
}
