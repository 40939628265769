@import "../../../../../../../../../../../variables";

.NatsMembershipInfo {
  overflow: auto;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  transform: translate3d(0, 0, 0);

  a {
    text-decoration: underline;
  }

  .CollapseContainer {
    .SubscriptionPanel {
      .ant-collapse-content {
        padding: 16px;
      }

      .TransactionPanel {
        position: relative;
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    
      .ant-table {
        table {
          word-break: break-all;
        }
      }

      .ant-table-wrapper {
        display: none;
    
        div:first-child {
          display: flex;
          flex-direction: column;
          flex: 1;
          min-height: 0;
        }
    
        .ant-table {
          .ant-table-body {
            background-color: $white;
            overflow: auto;
    
            table {
              border-radius: 0;

              .TypeColumn {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 300px;
              }

              .DateColumn {
                min-width: 200px;
                font-weight: bold;
              }
    
              .ant-table-thead {
                border-radius: 0;
    
                tr:first-child {
                  th:first-child {
                    border-top-left-radius: 0;
                  }
    
                  th:last-child {
                    border-top-right-radius: 0;
                  }
                }
              }
            }
          }
        }
      }
    
      .ant-list {
        flex: 1;
        min-height: 0;
        display: block;
        overflow: auto;
    
        li {
          padding: 0;
          margin-bottom: 10px;
    
          .ListCardWrapper {
    
            .ListItemLabel {
              font-weight: bold;
              margin-right: 5px;
              min-width: 100px;
            }
    
            p {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
                            
              a {
                word-break: break-all;
                text-align: justify;
                text-decoration: underline;
              }
            }
          }
        }
    
        li:last-child {
          margin-bottom: 0;
        }
      }
    
      @media (min-width: 1100px) {

        .ant-collapse-content-box {
          padding: 0px;
        }
    
        .ant-table-wrapper {
          display: flex;
          flex: 1;
          min-height: 0;
        }
    
        .ant-list {
          display: none;
        }
      }
    }
  }
}
