@mixin Valid {
  background: #11998e; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #38ef7d, #11998e); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@mixin Invalid {
  background: #ED213A; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #93291E, #ED213A); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #93291E, #ED213A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}