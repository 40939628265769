@import "../../../../../../../../../variables";

.EditTop10Modal {
	height: 80vh;
	width: calc(100vw - 20px);
	padding: 10px;
	display: flex;
	flex-direction: row;

	.BaseDragDropList {
		width: 100%;

		.BaseDragDropList-item {
			margin-bottom: 5px;

			.ItemContainer {
				width: 100%;
				pointer-events: none;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.ItemContainer-first-row {
					display: flex;
					flex-direction: row;
					align-items: stretch;
					flex: 1;
					min-width: 0;

					.IndexContainer {
						display: flex;
						width: 50px;
						justify-content: center;
						align-items: center;
						background: $black;
						border-top-left-radius: 10px;
						border-bottom-left-radius: 10px;

						h2 {
							color: $brown;
						}
					}

					.ItemDetails {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						height: 150px;
						border: 1px solid $purple-dark;
						border-right-width: 0;

						.ImageComponent {
							width: auto;
							height: calc(100% + 2px);

							.imageloader {
								flex-direction: row;
								justify-content: center;
							}
						}

						.Item-Title {
							margin: 10px;
							flex: 1;
							min-width: 0;
							font-weight: bold;
							font-size: 20px;
							text-align: center;
						}
					}
				}

				&.NumberOne {
					.ItemContainer-first-row {
						.IndexContainer {
							border-bottom-left-radius: 0;
						}

						.ItemDetails {
							border-bottom-width: 0;

							.ImageComponent {
								height: 100%;
							}
						}
					}
				}
			}

			.CloseContainer {
				border: 1px solid $purple-dark;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
				border-left-width: 0;

				.fa-times {
					margin: 10px 10px 0 0;
					font-size: 20px;
				}
			}

			.ImageSelectionWrapper {
				padding: 10px;
				border: 1px solid $purple-dark;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				text-align: center;

				.NoImageText {
					font-weight: bold;
					text-transform: uppercase;
					margin-bottom: 10px;
					font-size: 20px;
				}

				.ant-btn {
					width: 100%;
				}
			}

			.NumberOnePreviewWrapper {
				padding: 10px;
				display: flex;
				flex-direction: column;
				align-items: center;
				border: 1px solid $purple-dark;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				transform: translate3d(0, 0, 0);

				.ImageComponent {
					width: 100%;
					height: auto;
				}

				.ChangeImage {
					cursor: pointer;
					display: none;
					position: absolute;
					top: 10px;
					bottom: 10px;
					left: 10px;
					right: 10px;
					background-color: rgba(81, 87, 108, 0.7);
					color: $white;
				}

				&:hover {
					.ChangeImage {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						font-weight: bold;
						font-size: 40px;
					}
				}
			}

			.NumberOneImageLoadingIndicator {
				padding: 30px;
				border: 1px solid $purple-dark;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
			}

			&:first-child {
				.CloseContainer {
					border-bottom-width: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
	}

	@media (min-width: 1000px) {
		width: 600px;
	}
}

.EditTop10Modal-actions {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}
