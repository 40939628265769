@import "../../../../../../../../../../variables";

.BannerSetBannersAbstract {
  height: 100%;
  transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;

  .BannerSetBannersAbstract-Inner {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 10px;

    .ant-table-wrapper {
      td {
        height: 65px;
      }
    }

    .AllBanners {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border: 1px solid $ant-border;
      border-radius: 10px;
      margin-top: 10px;
      max-height: calc(100vh - 210px);

      .SegmentBase {
        background-color: $ant-background;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid $ant-border;
      }

      .Header {
        @extend .SegmentBase;
        padding: 10px 20px;
        font-size: 30px;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .TempChangesMsg {
          color: $red;
          text-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
      }

      .Footer {
        @extend .SegmentBase;
        padding: 10px;
      }

      .PushAll, .SaveAll {
        background-color: $blue-light;
        color: $white;
        font-weight: bold;
      }

      .SaveAll {
        margin-right: 5px;
      }

      .AllBanners-inner {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1;
        min-height: 0;
        padding: 10px 0 10px;

        .BaseVirtualList {
          flex: 1;
          min-height: 0;

          .BannersRow {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 10px;
            padding-left: 10px;
            height: calc(100% - 10px);

            .Banner {
              width: 16%;
              display: flex;
              flex-direction: column;
              justify-items: center;
              border: 1px solid $ant-border;
              border-radius: 5px;
              margin-right: 10px;
              padding: 7px 10px 10px;
              min-width: 250px;

              .fa-angle-double-left {
                height: fit-content;
                margin-left: 10px;
                margin-right: 10px;
                color: $blue-lighter;
                cursor: pointer;
                font-size: 20px;

                &:hover {
                  text-decoration: underline;
                }
              }

              .ant-input-number-handler-wrap {
                display: none;
              }

              .fa-angle-double-right {
                height: fit-content;
                margin-left: 10px;
                color: $blue-lighter;
                cursor: pointer;
                font-size: 20px;

                &:hover {
                  text-decoration: underline;
                }
              }

              .fa-times {
                height: fit-content;
                cursor: pointer;
                font-size: 18px;
                margin-right: 5px;
                color: $blue-light;
              }

              .ImageComponent {
                height: auto;
                width: 100%;
                cursor: pointer;

                .ImageLoadingSkeleton {
                  width: 100%;
                  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
                  background-size: 400% 100%;
                }

                img {
                  width: 100%;
                }
              }

              .NoImage {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
                min-height: 0;
                font-weight: bold;
                font-size: 25px;
              }

              &:not(.Hidden) {

                &:hover {
                  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                }
              }

              &.Hidden {
                border: none;
              }
            }
          }
        }
      }
    }
  }

  .UserActions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .ant-btn {
      margin-left: 10px;
    }
  }

  .Row {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;

    &.AlignCenter {
      align-items: center;
    }

    &.JustifySpaceBetween {
      justify-content: space-between;
    }
  }
}
