@import '../../../../../../../../../../../../../variables';

.MoviePlaylistDetailsFooter {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    border-color: $purple-light;
    font-weight: bold;
  }

  .MoviePlaylistDetailsFooter-inner {
    display: flex;
    flex-direction: row;
    button {
      margin-left: 10px;
    }
  }
}

@media (max-width: 1000px) {
  .MoviePlaylistDetailsFooter {
    display: none;
  }
}