.ErrorBoundary {
    text-align: center;
    padding: 4em 0;

    h3 {
        font-size: 1.8em;
    }

    p, button {
        font-size: 1.3em; 
    }

    button {
        border: none;
        background: #ddd;
        border-radius: 3px; 
        padding: .5em 1em;
        cursor: pointer;
    }
}

.ErrorMessage {
    text-align: center;
    padding: 0.2em 0;
    margin: 0.5em 0;
    font-size: 1.2em;
    background: #ff4d4f;
    font-weight: 600;
}