@import "../../../../../../../../../../variables";

.BannerSetCardAbstract { 
  box-shadow: 5px 5px 10px rgba(0,0,0, .4);
  cursor: pointer;

  &.ant-card {
    border-radius: 10px;
    border-color: $purple-light;
  }

  > .ant-card-head {
    border-radius: 5px;
    background-color: #6394c7; // $rebeca-purple;  
    color: $white;

    .ant-card-head-title {
      padding: 8px 0;
    }

    .Title {
      font-size: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .Actions {
        display: flex; 
        margin-right: 1.4em;
        
        .LinkButton {
          margin-left: 10px;
          font-weight: bold;
        }
      }

      .toggleIcon {
        position: absolute;
        right: 0px;
        font-size: 80%;
      }
    }
  }

  .BannersOrder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a {
      width: 16%;
    }

    .Banner {
      background-color: $grey-light;
      border-radius: 10px;
      border-color: $grey-light;

      .BannerTitle {
        display: flex;
        flex-direction: row;
        font-size: 1.6em;
        margin-right: 0.5rem;
        line-height: 1;

        .Numbering {
          font-weight: bold;
        }

        .Caption {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-bottom: 3px;

          .TooltipText {
            visibility: hidden;
            background-color: $grey-dark;
            color: $white;
            text-align: center;
            padding: 5px 10px;
            border-radius: 6px;
            position: absolute;
            left: 0;
            margin-left: 120px;
            z-index: 1;
            bottom: 95%;
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 18px;
            white-space: nowrap;

            &::after {
              content: "";
              position: absolute;
              top: 100%;
              left: 27px;
              border-width: 5px;
              border-style: solid;
              border-color: $grey-dark transparent transparent transparent;
            }
          }

          &:hover {
            .TooltipText {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      .ImageComponent {
        height: auto;
        width: auto;
      }

      .BannerStates { 
        .BannerState {
          position: relative;
          display: inline-block;
          margin-bottom: 20px;

          .TooltipText {
            visibility: hidden;
            background-color: $grey-dark;
            color: $white;
            text-align: center;
            padding: 5px 10px;
            border-radius: 6px;
            position: absolute;
            left: 0;
            margin-left: -17px;
            z-index: 1;
            bottom: 125%;
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 18px;
            white-space: nowrap;

            &::after {
              content: "";
              position: absolute;
              top: 100%;
              left: 27px;
              border-width: 5px;
              border-style: solid;
              border-color: $grey-dark transparent transparent transparent;
            }
          }

          &:hover {
            .TooltipText {
              visibility: visible;
              opacity: 1;
            }
          }

          i {
            margin-right: 10px;
            height: 30px;
            font-size: 30px;
            line-height: 30px;

            &.fa-film {
              color: $blue-lighter;
            }
          }
        }
      }

      &.Enabled {
        background-color: $white;
      }

      &:not(.Hidden) {
        cursor: pointer;

        &:hover {
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }

      }

      &.Hidden {
        width: 16%;
        background-color: $white;
      }
    }
  }
}