@import "../../../../../../../../../../../../variables";

.ConfigurePropertyAdsModal {
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  max-width: 1100px;
  max-height: 1100px;

  .ConfigurePropertyAdsModal-inner {
    padding: 10px;
    flex: 1;
    min-height: 0;
    overflow: auto;

    .MovieName, .PropertyName {
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 20px;
    }

    .ant-collapse-content-box {
      padding: 10px;

      .AdDetails {
        margin-bottom: 10px;
        border-color: $purple-dark;

        .ant-card-head {
          margin-left: 1px;
          margin-right: 1px;
          margin-top: 1px;
        }

        .AdCardTitle {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        .Text {
          font-weight: bold;
        }

        .AdFooter {
          flex: 1;
          min-width: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .ant-btn {
            background-color: $purple-light;
            border-color: $purple-dark;
            color: $white;
          }
        }
      }

      .AdDetails:last-child {
        margin-bottom: 0;
      }
    }
  }

  .SceneHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .SceneText {
      font-weight: bold;
    }

    .ant-btn {
      background-color: $purple-light;
      border-color: $purple-dark;
      color: $white;
    }
  }
}