@import "../../variables";

.SceneImage {
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
  }
}

.SceneImageLoader {
  transform: translate3d(0, 0, 0);

  .ant-spin {
    position: absolute;
    top: 43%;
    left: 45%;
  }
}
