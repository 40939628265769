@import '../../../../../../../../../../variables';

.LicensorsViewBody {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 10px;
  flex: 1 1;
  min-height: 0; 

  .ant-table-wrapper {   
    div:first-child {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
    }

    .ant-table {
      .ant-table-body {
        background-color: $white;
        overflow: auto;

        table {
          border-radius: 0;

          .ant-table-thead {
            border-radius: 0;

            tr:first-child {
              th:first-child {
                border-top-left-radius: 0;
              }

              th:last-child {
                border-top-right-radius: 0;
              }
            }
          }

          .NavigationLink {
            font-weight: bold;
            text-decoration: underline; 
          }
        }
      }
    }
  }

  .ant-list {
    flex: 1;
    min-height: 0;
    display: block;
    overflow: auto;

    li {
      padding: 0;
      margin-bottom: 10px;

      .ListCardWrapper {

        .ListItemLabel {
          font-weight: bold;
          margin-right: 5px;
        }

        p {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }

      .ListCardCFooter {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  .EditLicensorBtn {
    border-color: $purple-light;
    margin-left: 5px;
  }

  @media (min-width: 1000px) {
    padding: 0;
  
    .ant-list {
      display: none;
    }
  }
}
