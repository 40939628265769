@import "../../../../../../../../../../../variables";

.PropertiesSelectionModal {
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  max-width: 1000px;
  max-height: 1000px;

  .PropertiesSelectionModal-inner {
    padding: 10px;
    flex: 1;
    min-height: 0;
    overflow: auto;

    .SearchByNameFilter {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;

      .FilterLabel {
        white-space: nowrap;
        margin-right: 10px;
        font-weight: bold;
      }
    }

    .PropertiesList {

      .PropertyItem {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid $ant-border;
        margin-bottom: 2px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .PropertyName {
          font-weight: bold;
        }

        &.Checked {
          background-color: $blue-lighter;
        }

        &:hover {
          background-color: $blue-light;
        }
      }

      .PropertyItem:last-child {
        margin-bottom: 0;
      }
    }
  }
}