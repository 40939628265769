@import "../../../../../../../../../../variables";

.MoviePlayListsView {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  .BaseNavigationItem {
    button {
      border-color: $purple-light;
      font-weight: bold;
    }
  }

  >:nth-child(2) {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: row;
  }
}
