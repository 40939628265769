@import '../../../../../../../../../variables';

.MailMainView {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  transform: translate3d(0, 0, 0);
  padding: 0;
    
  .MailViewBody {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $white;
    padding: 10px;
    overflow: auto;

    .MailCard-footer {
      flex-direction: row;
      display: flex;
      justify-content: flex-end;

      .ant-btn {
        border-color: $purple-light;
        font-weight: bold;
      }

      .ant-btn:first-child {
        margin-right: 10px;
      }
    }
    
    .ant-table-wrapper {
      display: none;
  
      div:first-child {
        width: 100%;
      }

      .MailMainViewActions {
        justify-content: center;
      
        button:first-child {
          margin-right: 15px;
        }
      
        button {
          border-color: $purple-light;
          font-weight: bold;
        }
      }
    }
  
    .ant-list {
      flex: 1;
      min-height: 0;
      display: block;
      overflow: auto;
  
      li {
        padding: 0;
        margin-bottom: 10px;
  
        .ListCardWrapper {
  
          .ListItemLabel {
            font-weight: bold;
            margin-right: 5px;
          }
  
          p {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
  
        .ListCardCFooter {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          button:first-child {
            margin-right: 15px;
          }
        
          button {
            border-color: $purple-light;
          }
        }
      }
  
      li:last-child {
        margin-bottom: 0;
      }
    }
  
    @media (min-width: 1000px) {
      padding: 0;

      .MailViewBody {
        overflow: hidden;
      }
  
      .ant-table-wrapper {
        display: flex;
        flex: 1;
        min-height: 0;
      }
  
      .ant-list {
        display: none;
      }
    }
  }

  @media (min-width: 1000px) {
    .MailViewBody {
      overflow: hidden;
    }
  }
}

