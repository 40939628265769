.EditRecurrentsModal {
    .EditRecurrentsModal-inner {
        width: 100vw;
        height: 75vh;
        max-width: 85em;
        overflow: auto;
        padding: 1em;
    }

    .Btn {
        border-color: #7a7de5;
        margin: 0 3px;
        font-weight: 600;
    }

    .ant-select,
    .ant-calendar-picker {
        width: 100%;
    }

    input[type=number] {
        height: 24px;
    }

    .ant-table-placeholder {
        z-index: 0;
    }
    
    .Table th {
        background-color: #fafafa !important;
    }
}