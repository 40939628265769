@import "../../../../../../../../../../../../variables";
@import "../../../shared/ValidationMixin";

.PropertiesCollapsePanel {

  .Property {

    .Header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .PropertyName {
        font-weight: bold;
      }

      .Section2 {

        .ant-btn {
          background-color: $purple-light !important;
          border-color: $purple-light !important;
          margin-right: 10px;
        }
      }
    }

    &.Valid {
      @include Valid;
    }

    &.Invalid {
      @include Invalid;
    }
  }
}