@import "../../variables";

.BaseCard {

  &.ant-card {
    border-radius: 10px;
    padding-bottom: 0;

    >.ant-card-head {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    >.ant-card-body {
      padding: 0;
    }
  }

  &.Empty {
    >.ant-card-head {
      border-radius: 10px;
    }

    >.ant-card-body {
      display: none;
      padding-bottom: 0;
    }
  }

  .BaseCard-body {
    display: flex;
    flex-direction: column;

    .BaseCard-body-children {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 24px;
    }

    .BaseCard-body-footer {
      padding: 10px;
      border-top: 1px solid $ant-border;
    }
  }
}
