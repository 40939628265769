@import "../../../../../../../../../variables";

.MovieDetails {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  transform: translate3d(0, 0, 0);

  button {
    border-color: $white;
    font-weight: bold;
    margin-right: 5px;
  }

  > div:nth-child(2) {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }
}
