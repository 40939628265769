@import "../../../../../../../../../../variables";

.StudioPlayerConfigCollapse {

  &.ant-collapse {
    border: none;

    .ant-list {
      .ant-list-header {
        font-weight: bold;
      }
    }

    .Studio {
      margin-bottom: 10px;
      border-top: 3px solid $purple-dark;
      border-left: 3px solid $purple-dark;
      border-right: 3px solid $purple-dark;
      border-radius: 4px !important;

      .MovieCollapse-Container {
        margin-top: 10px;
      }

      .Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .Text {
          font-weight: bold;
        }

        .Section2 {

          .ant-btn {
            margin-right: 10px;
            background-color: $purple-dark;
            border-color: $purple-dark;
            color: $white;
          }
        }
      }

      .StudioConfigurationFooter {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    .Studio:last-child {
      margin-bottom: 0;
    }
  }
}