@import "../../../variables";

.DeleteImagesModal {
  height: calc(100vh - 120px);
  overflow: auto;
  padding: 10px 0 10px 20px;
  display: flex;
  flex-direction: column;
  background-color: $purple-dark;

  .DeleteImagesModal-description {
    color: $white;
    padding: 10px;
    font-size: 24px;
  }

  .DeleteImagesModal-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow: auto;

    .DeleteImagesModal-file {
      margin-bottom: 10px;
      margin-right: 10px;
      width: 150px;
      height: 290px;
      display: flex;
      flex-direction: column;

      .ant-card-cover {
        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;

        .ImageComponent {
          width: 100%;
        }

        img {
          max-width: 150px;
        }
      }

      .ant-card-body {
        flex: none;
      }

      .DeleteImagesModal-file-mark {
        font-size: 84px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .DeleteImagesModal-file-mark-background {
          margin: -1px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          flex: 1;
          min-width: 0;
          background-color: $grey-light;
          opacity: .8;
        }

        .anticon {
          color: $delete-images-modal-icon-color;
          z-index: 1;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .DeleteImagesModal {
    height: 720px;
    max-height: calc(100vh - 120px);
    width: 1000px;

    .DeleteImagesModal-images {
      justify-content: flex-start;
    }
  }
}

.MainModalDialog {
  > .ant-card-bordered {
    .ant-card-body {

      .DeleteImagesModal-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
        min-width: 0;

        button:last-child:disabled {
          color: $purple-dark;
        }

        .DeleteImagesModal-cancel {
          display: none;
        }

        @media (min-width: 1000px) {
          .DeleteImagesModal-cancel {
            display: inline-block;
          }
        }
      }
    }
  }

  &.Maximized {
    .DeleteImagesModal {
      width: auto;
      height: auto;
      flex: 1;
      min-height: 0;
    }
  }
}
