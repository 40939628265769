@import "../../../../../../../../../../../../variables";
@import "../../../shared/ValidationMixin";

.MoviesCollapsePanel {

  &.ant-collapse {
    border: none;

    > .ant-collapse-header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .Movie {

      .Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .MovieName {
          font-weight: bold;
        }

        .Section2 {

          .ant-btn {
            margin-right: 10px;
            background-color: $purple-dark;
            border-color: $purple-dark;
            color: $white;
          }
        }
      }

      &.Valid {
        @include Valid;
      }

      &.Invalid {
        @include Invalid;
      }
    }
  }
}