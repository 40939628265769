@import "../../variables";

.BaseGlobalImagesAssignSelectionModal {
  width: calc(100vw - 50px);
  height: calc(100vh - 130px);
  padding: 10px;
  display: flex;
  flex-direction: column;

  .StarSearch {
    width: 100%;
  }

  .BaseGlobalImagesAssignSelectionModal-images-wrapper {
    flex: 1;
    min-height: 0;
    border: 1px solid $ant-border;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .BaseGlobalImagesAssignSelectionModal-images-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid $ant-border;
      padding: 10px;

      .BaseGlobalImagesAssignSelectionModal-images-actions-label {
        font-weight: bold;
      }

      .ant-select {
        margin-left: 10px;
      }
    }

    .BaseGlobalImagesAssignSelectionModal-images {
      flex: 1;
      min-height: 0;
      overflow: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px;

      .SingleFile {
        height: 220px;
        margin: 10px;
        width: 330px;
      }
    }
  }

  .ant-input {
    margin-top: 10px;
  }
}

button:disabled {
  color: $purple-dark !important;
}