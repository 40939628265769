@import "../../../../../../../../../../variables";

.NatsUsersViewBody {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  background-color: $white;
  transform: translate3d(0, 0, 0);

  .UsersTable {
    flex: 1;
    display: none;
    flex-direction: column;
    min-height: 0;

    .ant-spin-nested-loading {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;

      .ant-spin-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0;

        .ant-table {
          display: flex;
          flex-direction: column;
          flex: 1;
          min-height: 0;

          .ant-table-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            flex: 1;
            min-height: 0;

            .ant-table-scroll {
              height: 100%;
              display: flex;
              flex-direction: column;
              min-height: 0;
            }

            .ant-table-body {
              overflow: auto;
              flex: 1;
              min-height: 0;
              background-color: $white;

              .NavigationLink {
                font-weight: bold;
                text-decoration: underline;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              }
            }

            .ant-table-placeholder {
              height: 100%;
            }
          }
        }
      }
    }
  }

  .ListView {
    display: block;
    flex: 1;
    min-height: 0;
    overflow: auto;

    .LinkButton {
      float: right;
    }

    .BaseCard {
      margin: 10px;

      .ant-card-head {
        color: $purple-light;
        font-weight: bold;
        font-size: 1rem;
      }

      .BaseCard-body-children {
        padding: 0;

        table {
          border-left-width: 0;
          border-top-width: 0;

          tr {
            td:nth-child(1) {
              font-weight: bold;
              border-left-width: 0;
            }

            td:nth-child(2) {
              font-weight: bold;
              border-right-width: 0;
            }
          }

          tr:last-child {
            td {
              border-bottom-width: 0;
            }
          }
        }
      }

      > button {
        border-color: $purple-light;
      }
    }
  }

  @media (min-width: 1000px) {

    .ListView {
      display: none;
    }

    .UsersTable {
      display: flex;
    }
  }

  .StatusItem {
    font-weight: bold;
    font-size: 1rem;
    padding: 5px;

    button {
      min-width: 100px;
      cursor: auto;
    }

    .Active {
      background-color: $user-active;
      color: $white;
    }

    .Expired {
      background-color: $user-expired;
      color: $white;
    }

    .NeverJoined {
      background-color: $user-never-joined;
      color: $white;
    }
  }

  .ant-btn.LinkButton {
    border: 1px solid #7a7de5;
    margin: 0 5px;
  }
}
