@import '../../../../../../../../../variables';

.UsersMainSection {
  flex: 1 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  >:first-child {
    display: flex;
    flex-direction: column;
    height: 100%;
    transform: translate3d(0, 0, 0);
  }

  >:nth-child(2) {
    flex: 1 1;
    min-height: 0;

    >:first-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}