@import "../../../../../../../../../../variables.scss";

.UsersViewHeader {
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: $white-grey;

  .UsersViewHeader-mainWrapper {
    display: none;
    flex-direction: column;
    align-items: stretch;

    &.FiltersToggled {
      display: flex;
    }

    .UsersViewHeader-filterLabel {
      margin-right: 10px;
      font-weight: bold;
    }

    .UsersViewHeader-filterWrapper:first-child {
      flex: 1;
    }

    .UsersViewHeader-filterWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .ant-select {
        min-width: 300px;
      }

      input {
        flex: 1;
      }
    }

    .UsersViewHeader-filterWrapper:nth-child(2) {
      margin-left: 0;
      margin-bottom: 10px;
      min-width: 300px;
    }

    button {
      margin-left: 0;
    }
  }

  .UsersViewHeader-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    padding-right: 5px;

    .anticon {
      margin-left: 20px;
    }
  }

  @media (min-width: 1000px) {
    flex-direction: row;

    .UsersViewHeader-mainWrapper {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: row;

      .UsersViewHeader-filterWrapper {
        flex-direction: row;
        align-items: center;
      }

      .UsersViewHeader-filterWrapper:nth-child(2) {
        margin-left: 10px;
        margin-bottom: 0;
      }

      button {
        margin-left: 10px;
      }
    }

    .UsersViewHeader-inner {
      display: none;
      margin-bottom: 0;
    }
  }
}
