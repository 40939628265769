@import '../../../../../../../../../../variables';

.PayoutsByMovieMainView {
  display: flex;
  flex-direction: column;

  .PayoutsByMovieBody {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $white;
    padding: 10px;
    overflow: auto;

    .ant-table-placeholder {
      display: none;
    }

    .ant-table-wrapper {
      display: none;
    }

    .ant-list {
      flex: 1;
      min-height: 0;
      display: block;
      overflow: auto;

      li {
        padding: 0;
        margin-bottom: 10px;

        .ListCardWrapper {

          .ListItemLabel {
            font-weight: bold;
            margin-right: 5px;
          }

          p {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .ListCardCFooter {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          button:first-child {
            margin-right: 15px;
          }

          button {
            border-color: $purple-light;
          }
        }
      }

      li:last-child {
        margin-bottom: 0;
      }
    }

    .PayoutsByMovie-listCard-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    @media (min-width: 1000px) {
      padding: 0; 

      .ant-table-wrapper {
        display: flex;
        flex: 1;
        min-height: 0;

        > div {
          width: 100%;
        }
      }

      .ant-list {
        display: none;
      }
    }
  }
}