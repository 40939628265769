@import "../../../variables";

.SignInWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  flex: 1;
  height: 90%;

  .SignInWrapper-inner {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    box-shadow: 5px 5px 5px $login-page-shadow;
    background: $login-page-secondary; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, $login-page-primary, $login-page-secondary); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, $login-page-primary, $login-page-secondary); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    .login-form {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 30px;
      background: $white;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      .SignInWrapper-company-min {
        padding: 10px;
        font-size: 50px;
        margin-bottom: 40px;
      }

      .ant-form-item {
        width: 90%;
      }

      .ant-form-item:last-child {
        margin-top: 50px;
        height: 72px;

        .ant-form-item-control {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .ant-form-item-children {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .login-form-button {
              width: 80%;
              background-color: $login-page-button;
              color: $white;
            }

            .login-form-button:disabled {
              color: $login-page-darkened-text;
            }
          }
        }
      }

      .ant-input, .ant-input-password {
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0 !important;
        border-right-color: transparent !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;

        ::selection {
          background: none !important;
        }
      }
    }

    .SignInWrapper-company {
      flex: 1;
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: 40px;

      .SignInWrapper-company-description {
        font-size: 20px;
        color: $login-page-darkened-text;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 30px;
      }

      @media (min-width: 1100px) {
        font-size: 60px;
        flex: 2;

        .SignInWrapper-company-description {
          font-size: 25px;
          padding-left: 0;
        }
      }
    }

    @media (min-width: 1100px) {
      .ant-form-item {
        width: 70%;
      }
    }
  }

  @media (min-width: 1100px) {
    height: 74%;

    .SignInWrapper-inner {
      width: 90%;
      flex-direction: row;
      border-radius: 0;

      .SignInWrapper-company-min {
        display: none;
      }

      .login-form {
        border-radius: 0;
      }

      .SignInWrapper-company {
        display: flex;
      }
    }
  }

  .ErrorMessage {
    color: $login-page-error;
  }

  .SignInTitle {
    padding-bottom: 50px;
    font-size: 30px;
  }

  .SpinerWrapper {
    display: flex;
    justify-content: center;
  }
}
