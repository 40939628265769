@import '../../../../../../../../../variables';



.PayoutsByMonth {
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  transform: translate3d(0, 0, 0);

  .ChangeStatusBtn {
    margin-left: 5px;
  }

  .PayoutsByMonthBody {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $white;
    padding: 10px;
    overflow: auto;
    transform: translate3d(0, 0, 0);

    .ant-table-wrapper {
      display: none;

      .ant-table-placeholder {
        display: none;
      }
    }

    .ant-list {
      flex: 1;
      min-height: 0;
      display: block;
      overflow: auto;

      li {
        padding: 0;
        margin-bottom: 10px;

        .ListCardWrapper {

          .ListItemLabel {
            font-weight: bold;
            margin-right: 5px;
          }

          p {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .PayoutsByMonth-listCard-footer {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }

        .ListCardCFooter {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          button:first-child {
            margin-right: 15px;
          }

          button {
            border-color: $purple-light;
          }
        }
      }

      li:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: 1100px) {
      padding: 0;

      .ant-table-wrapper {
        display: flex;
        flex-direction: row;
        flex: 1;
        // min-height: 0;

        >div {
          width: 100%;
        }
      }

      .ant-list {
        display: none;
      }
    }
  }
  .ActionBtn {
    margin: 0 3px;
    padding: 5px 8px;
  }
}

@media (min-width: 1100px) {
  .PayoutsMainView {
    overflow: hidden;
  }
}