@import '../../../../../../../../../../variables';

.StarsViewContent {
  flex: 1;
  min-height: 0;
  overflow: auto;
  background-color: $white;
  padding: 10px;

  .StarsViewList-list {
    display: block;

    .BaseCard {
      margin-bottom: 10px;

      .ant-card-head {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        .ant-card-head-title {
          font-size: 1.3rem;
        }
      }

      .StarsViewList-list-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > span {
          font-weight: bold;
        }
      }
    }

    .BaseCard:last-child {
      margin-bottom: 0;
    }
  }

  .StarsViewList-table {
    display: none;
  }

  .StarsViewList-card-footer {
    display: flex;
    justify-content: flex-end;

    button {
      border-color: $purple-light;
    }
  }

  .EditStar {
    border-color: $purple-light;
    margin-left: 5px;
  }

  @media (min-width: 1000px) {
    overflow: hidden;
    padding: 0;

    .StarsViewList-list {
      display: none;
    }

    .StarsViewList-table {
      display: block;
    }
  }
}
