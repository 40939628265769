.BannerSetPropertiesMainView {
  height: 100%;
  width: 100%;
  transform: translate3d(0, 0, 0);
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;

  .BannerSetPropertiesDefaultBanners {
    margin-bottom: 20px;
  }

  .BannerSetCard {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}