.EditSpecialCategoriesModal {
	height: calc(100vh - 130px);
	width: calc(100vw - 20px);
	padding: 10px;
	display: flex;
	flex-direction: column;
	min-height: 200px;
  
	.EditCatalogModal-tab {
	  height: calc(100vh - 200px);
	  padding-bottom: 20px;
	  padding-right: 5px;
	  overflow: auto; 
	}

	.PropertyItemsSelect {
		margin-top: 1rem;
		
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
	}
  
	.ant-table-wrapper:first-child {
	  td {
		font-weight: bold;
	  }
	}
  
	@media (min-width: 1000px) {
	  width: 1000px;
	}
  }
  