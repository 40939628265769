@import "../../../../../../../../../../variables";

.SelectStudioModal {
  height: 600px;
  width: 800px;

  .SelectStudioModal-inner {
    flex: 1;
    min-height: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .NameFilter {
      margin-bottom: 20px;

      .Label {
        font-weight: bold;
        margin-bottom: 3px;
      }
    }

    .StudiosList {
      flex: 1;
      min-height: 0;
      overflow: auto;

      .Studio {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $ant-border;
        padding: 5px;
        margin-bottom: 2px;
        border-radius: 5px;

        .Name {
          font-weight: bold;
        }

        &.Checked {
          background-color: $blue-lighter;
        }

        &:hover {
          background-color: $blue-light;
          cursor: pointer;
        }
      }

      .Studio:last-child {
        margin-bottom: 0;
      }
    }
  }
}