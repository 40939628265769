@import '../../../../../../../../../../variables.scss';

.EditHTMLContentModal {

  .EditHTMLContentModal-inner {
    height: 75vh;
    width: 95vw;
    padding: 1em;

    .Section {
      margin: 0 .5em 1em;

      .Label {
        font-weight: 600;
      }
    }
  }
}