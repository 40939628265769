@import "../../../variables";

.MainModalDialog {
  .UploadImages-browse-wrapper { 
    height: 80vh;  
    font-size: 20px; 
    display: flex;
    flex-direction: column;

    @media (min-width: 1000px) {
      width: 100vw; 
      max-width: 500px;
    }

    .anticon {
      font-size: 36px;
      margin-bottom: 10px;
    }

    > div {
      margin-bottom: 10px;
    }

    div:last-child {
      color: $purple-light;
      font-weight: bold;
      cursor: pointer;
    }

    input {
      display: none;
    }

    .UploadImages-drag-drop {
      display: flex;
      flex-direction: column; 
      height: 170px;
      align-items: center;
      justify-content: center;
      margin: 20px;
      border-radius: 10px;
      border: 2px dashed $purple-light;
      background-color: $white-grey;

      .DragDropText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .UploadImages-list {
      padding: 0 20px;
      overflow: auto;
      flex: 1;  

      .ant-list-items {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    &.Dragging {
      background-color: $white;
    }

    &.FilesListActive {
      background-color: $white;
      border: none;
    } 
  }

  &.Maximized {

    .UploadImages-browse-wrapper { 
      .UploadImages-list {

        .ant-list-items {
          flex-direction: row;
          justify-content: flex-start;

          .SingleFile {
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .UploadImages-actions {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button:last-child:disabled {
      color: $purple-dark !important;
    }
  } 
}
