@import "../../../../../../../../../../../../variables";

.MovieDetailsEnableDropdown {
  display: flex;
  flex-direction: column;

  &.MovieEnabled {
    background-color: $movie-enabled !important;
    color: $white !important;
  }

  &.MovieDisabled {
    background-color: $movie-disabled !important;
    color: $white !important;
  }

  >span {
    margin-top: 4px;
  }
}