@import '../../../../../../../../../variables.scss';

.StarDetails {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  transform: translate3d(0, 0, 0);

  > :nth-child(3) {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .ContentInfoView {
      height: 100%;
    }
  }

  button {
    border-color: $white; 
    margin-right: 5px;
  }

  @media (min-width: 1000px) {
    border-left-width: 5px;
  } 
}
