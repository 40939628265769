@import "../../../../../variables";

.MainDashboardHeader-AvatarDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.ant-dropdown-link {
    padding-right: 20px;
    margin-right: 10px;

    span {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      color: $white;
      margin-right: 5px;
    }

    .anticon {
      color: $white;
      font-size: 16px;
    }

    .UserName {
      margin-right: 5px;
      color: $white;
      font-size: 1.2rem;
      margin-bottom: 3px;
    }
  }
}

.MainDashboardHeader-AvatarDropdownMenu {
  i {
    margin-right: 5px;
  }
}
