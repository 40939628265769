@import "../../../../../../../../../../../variables";

.MovieScene {
  border-color: $purple-light !important;

  .BaseCard-body-children {
    padding: 0;
    flex-direction: row;
    position: relative;

    .ant-table {
      border: none;

      &-wrapper {
        flex: 1;
        @media (max-width: 1000px) {
          padding-left: 0;
        }
      }

      table {
        border: none;

        td:first-child {
          font-weight: bold;
          width: 150px;
        }

        td:last-child {
          border-right-width: 0;
        }

        tr:last-child {

          td {
            border-bottom-width: 0;
          }
        }

        @media (min-width: 1000px) {
          td:first-child {
            font-weight: bold;
            width: 300px;
          }
        }
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .SceneImage {
    margin: auto 10px;
    height: 100%;

    @media (min-width: 1000px) {
      padding-top: 1px;
    }
  }

  .MovieSceneCardTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .ant-tag {
      font-weight: bold;
      font-size: 1rem;
    }

    .ant-btn {
      border-color: $purple-light;
      font-weight: bold;
    }

    .MovieScene-actions {
      display: none;

      .ant-btn {
        margin-right: 10px;
      }

      .ant-btn:last-child {
        margin-right: 0;
      }
    }

    .MovieScene-dropdown {
      display: block;
      font-size: 20px;
    }

    @media (min-width: 1000px) {
      .MovieScene-actions {
        display: block;
      }

      .MovieScene-dropdown {
        display: none;
      }
    }
  }

  .BaseCard-body {

    .BaseCard-body-children {
      flex-direction: row;
      padding: 0 !important;

      .ant-table {
        border: none;

        table {
          border: none;

          td:first-child {
            font-weight: bold;
            width: 150px;
          }

          td:last-child {
            border-right-width: 0;
          }

          tr:last-child {

            td {
              border-bottom-width: 0;
            }
          }

          @media (min-width: 1000px) {
            td:first-child {
              font-weight: bold;
              width: 300px;
            }
          }
        }
      }
    }
  }

  .NoImage {
    display: flex;
    position: relative;
    width: 200px;
    height: 200px;
    align-self: center;
    align-items: center;
    justify-content: center;
    user-select: none;
    @media (min-width: 1000px) {
      align-self: normal;
      height: 100%;
      position: absolute;
    }
  }
}

.MovieScene-dropdown-actions {
  .anticon {
    margin-right: 5px;
  }
}

