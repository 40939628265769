@import "../../../../../../../../../../../variables";

.SelectPropertiesModal {
  width: calc(100vw - 100px);
  height: calc(100vh - 60px);
  max-height: 600px;
  max-width: 800px;

  .SelectPropertiesModal-inner {
    flex: 1;
    min-height: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .NameFilter {
      margin-bottom: 20px;

      .Label {
        font-weight: bold;
        margin-bottom: 3px;
      }

      .Actions {
        display: flex;

        .AddNewCode {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          background: #1890ff;
          font-size: 16px;
        }
      }
    }

    .PropertiesList {
      flex: 1;
      min-height: 0;
      overflow: auto;

      .Property {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $ant-border;
        padding: 5px;
        margin-bottom: 2px;
        border-radius: 5px;

        .Name {
          font-weight: bold;
        }

        &.Checked {
          background-color: $blue-lighter;
        }

        &:hover {
          background-color: $blue-light;
          cursor: pointer;
        }
      }

      .Property:last-child {
        margin-bottom: 0;
      }
    }
  }
}