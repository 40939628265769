@import "../../../../../../../../../../../variables";

.MovieDetailsInfosTabTable {
  &.ant-table-wrapper {
    flex: 1;
    min-width: 0;

    td:first-child {
      font-weight: bold;
      width: 150px;
    }

    tr {
      min-height: 66px;

      td {
        min-height: 66px;
      }
    }

    .EnabledDisabledTag {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      background-color: $movie-disabled;
      color: $white;
      border-radius: 3px;
      width: 101px;

      &.Enabled {
        background-color: $movie-enabled;
      }
    }

    .ant-select {
      width: 100%;
    }

    .DisplayField {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .Label {
        font-weight: bold;
      }
    }

    @media (min-width: 1000px) {
      td:first-child {
        width: 500px;
      }
    }

    .StatusDropdownWrapper {
      display: flex;
      flex-direction: column;

      .MovieOnline {
        width: 101px;
        background-color: $movie-online;
        color: $white;
        margin-right: 0;
      }

      .MovieOffline {
        width: 101px;
        background-color: $movie-offline;
        color: $white;
      }

      .MovieEnabled {
        background-color: $movie-enabled;
        color: $white;
      }

      .MovieDisabled {
        background-color: $movie-disabled;
        color: $white;
      }
    }
  }
}

.MovieDetailsInfosTabTable-enabledDisabled {

  &.ant-dropdown-menu {
    padding: 0 2px 2px 2px;

    li:first-child {
      background-color: $movie-enabled;
      color: $white;
    }

    li:first-child:hover {
      font-weight: bold;
    }

    li:last-child {
      background-color: $movie-disabled;
      color: $white;
    }

    li:last-child:hover {
      font-weight: bold;
    }
  }
}
