@import "../../../../../../../../../variables";

.MoviesViewWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  transform: translate3d(0, 0, 0);

  > :first-child {
    flex: auto;
    min-height: 0;
    display: flex;
    flex-direction: column;
    
    > :first-child {
      flex: auto;
      min-height: 0;
      display: flex;
      flex-direction: column;
    }
  }

  .MoviesViewList {
    flex: 1;
    min-height: 0;
    overflow: auto;
  }

  .SpinnerWrapper {
    display: flex;
    justify-content: center;
    flex: 1;
    align-items: center;

    .ant-spin {
      margin-right: 10px;
      margin-top: 5px;
    }

    .LoadingText {
      font-size: 1rem;
      font-weight: bold;

      @keyframes blink {
        0% {
          opacity: .2;
        }
        20% {
          opacity: 1;
        }
        100% {
          opacity: .2;
        }
      }

      span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
      }

      span:nth-child(2) {
        animation-delay: .2s;
      }

      span:nth-child(3) {
        animation-delay: .4s;
      }
    }

    &.Loading {
      position: fixed;
      height: 100%;
      width: 100%;
      background: $white-grey;
      opacity: .4;
    }
  }
}
