@import "../../../../../../../../../variables";

.CategoryDetails {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  transform: translate3d(0, 0, 0);

  .BaseNavigationItem {
    button {
      margin-left: 3px;
      font-weight: bold;
      border-color: $purple-light;
    }
  }

  .CategoryDetailsImagesTab {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .BaseImagesDisplayList {
      padding:10px;
      display: block;
    }
  
    .BaseImagesDisplay {
      flex: 1;
      min-height: 0;

      .ImagesGridDisplay-card {
        height: 300px;
      }  
    }
  }

  .TabsComponent {
    background-color: $white;
    flex: 1;
    min-height: 0;

    .ant-tabs-bar {
      margin-bottom: 0;
    }

    .ant-tabs-tabpane {
      overflow: auto;
    }
  }

  @media (min-width: 1000px) {
    .CategoryDetailsImagesTab{
      .BaseImagesDisplayList {
        display: none;
      }
    }
  }
}
