$white: #ffffff;
$white-grey: #f0f2f5;
$black: #000000;
$rebeca-purple: #663399;
$purple-light: #7a7de5;
$purple-dark: #51576c;
$grey-dark: #484e5e;
$grey-light: #7a8198;
$grey-lighter: #c0c1f5;
$blue-light: #2196f3;
$blue-lighter: #6BBBFF;
$link-color: #1890ff;
$brown: #b39d61;

$ant-border: #e8e8e8;
$ant-background: #fafafa;

//input 
$invalid-input: #FF0000;

// Movies status colors
$movie-enabled: #87d068;
$movie-disabled: #7a8198;
$movie-online: #108ee9;
$movie-offline: #ff4d4f;

// Studio status colors
$studio-enabled: #87d068;
$studio-disabled: #7a8198;

// Users status colors
$user-enabled: #87d068;
$user-disabled: #7a8198;

// Banner status colors
$banner-enabled: #87d068;
$banner-disabled: #7a8198;

//Header gradient colors
$header-gradient-primary: #FF3CAC;
$header-gradient-secondary: #784BA0;
$header-gradient-ternary: #2B86C5;

// Side menu gradient colors
$side-menu-gradient-primary: #0f0c29;
$side-menu-gradient-secondary: #302b63;
$side-menu-gradient-ternary: #24243e;

// Login page colors
$login-page-primary: #4286f4;
$login-page-secondary: #373B44;
$login-page-shadow: #333;
$login-page-button: #394761;
$login-page-darkened-text: #808080;
$login-page-error: #FF0000;

//Base Navigation colors
$base-navigation-title-color: #2B86C5;
$base-navigation-arrow-color: #7f7f7f;
$base-navigation-subtitle-color: #bfbfbf;

//Base Horizontal Navigation colors
$base-horizontal-navigation-background: #cccccc;
$base-horizontal-navigation-placeholder: #979797;
$base-horizontal-navigation-bottom-line: #a9a9a9;
$base-horizontal-navigation-border: #78a5cc;
$base-horizontal-navigation-bottom-line-selected: #6394c7;

//Delete Images Modal colors
$delete-images-modal-icon-color: #39FF14;

//Spiner colors
$spinner-background-color: #171717;
$spinner-after-background-color: #ffffff;

//Header Search colors
$header-search-background-color: #696cd2;

//Movie Card colors
$movie-card-header-color: #7a7de5;

//Main List colors
$main-list-border-color: #e8e8e8;

//Scene Main List colors
$scene-main-list-border-color: #e8e8e8;

//Status Info colors
$status-info-text-color: #fff;

//Not Found colors
$not-found-background-color: #00688B;
$not-found-secondary-text-color: #fff;

// NATS users status colors
$subscription-enabled: #87d068;
$subscription-disabled: #7a8198;

// NATS Users status colors
$user-active: #87d068;
$user-expired: #FF0000;
$user-never-joined: #7a8198;

// PLAYER colors
$player-background: #2B333F;
$player-user-rated-movie-positive: #39FF14;
$player-user-rated-movie-negative: #FF0000;

$ad-config-verified: #2dd128;

//CacheButton
$cache-button: #FF0000;

$red: #FF0000;




