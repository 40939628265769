 .EditSearchOptionsModal-inner {
   min-height: 100px;
   min-width: 450px;
   padding: 20px 15px;
   font-weight: 600;
   
   label {
     display: block;
     padding: 5px 10px;
     font-size: 1.2em;
     color: #000;
  
     &:hover {
       color: #2196f3;
     } 
   }
 }