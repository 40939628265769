@import '../../../../../../../../../../variables.scss';

.BannersViewBody {
  display: flex;
  flex-direction: column;
  height: 100%;

  .BannersViewBody-inner {
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 0;
    flex: 1;
    overflow-y: auto;
    background: $white;
    border: 1px solid $ant-border;

    .BannerCard-body {
      flex-direction: row;
    }

    .BannerCard {
      width: 100%;
      margin-bottom: 10px;
      margin-right: 10px;
      border-color: $purple-light;
      border-width: 2px;
    }

    .BannerCard-title {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    @media (min-width: 1000px) {
      .BannerCard {
        width: calc(20% - 10px);
      }
    }
  }
}
