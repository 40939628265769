@import '../../../../../../../../../../variables';

.AddCategoriesModal {
  height: 500px;
  width: calc(100vw - 20px);
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .SearchInput {
    margin: 5px;
    width: calc(100% - 10px);

    .ant-input {
      min-height: auto;
    }
  }

  .Categories-Container {
    padding: 5px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .BaseCard {
      width: calc(50% - 5px);
      margin-top: 10px;
      border: 1px solid $purple-dark;
      height: fit-content;

      .BaseCard-body-children {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .Title {
          font-size: 16px;
          font-weight: bold;
        }

        .ant-checkbox-wrapper {
          justify-content: flex-end;
          display: flex;
        }
      }
    }
  }

  @media (min-width: 1000px) {
    width: 650px;
    height: 80vh;

    .BaseCard {
      width: calc(33% - 5px);
    }
  }
}

.AddCategoriesModal-actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}
