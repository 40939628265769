
@import "../../../../../../../../../../variables";

.DetailsTab {
  .BaseCard-body-children {
    padding: 0 !important;

    .UserActions {
      display: flex;
      justify-content: flex-end;

      .ant-btn {
        border-color: #663399;
        width: fit-content;
        font-size: 18px;
      }
    }
  }

  .ant-table {

    table {
      border-left-width: 0;

      tr {         
        th:last-child {
          border-right-width: 0;
        }

        td:first-child {
          border-left-width: 0; 
        }

        td:last-child {
          border-right-width: 0;
        }
      }

      tr:last-child {
        td {
          border-bottom-width: 0;
        }
      }

      td:first-child {
        font-weight: bold;
      }

      .Checkbox-Container {
        .ant-checkbox {
          margin-right: 10px;
        }
      }

      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
}
