@import '../../../../../../../../../../../../variables';

.SceneMainList {
  flex: 1;
  border-bottom: 1px solid $scene-main-list-border-color;
  background-color: $white;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .SceneMainListActions {
    display: flex;
    justify-content: flex-end;

    a:first-child {
      margin-right: 15px;
    }

    button {
      border-color: $purple-light;
    }
  }

  .ant-list {
    flex: 1;
    min-height: 0;
    display: block;
    overflow: auto;
    padding: 10px;

    li {
      padding: 0;
      margin-bottom: 10px;

      .ListCardWrapper {

        .ListItemLabel {
          font-weight: bold;
          margin-right: 5px;
        }

        p {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }

      .ListCardCFooter {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  .ant-table-wrapper {
    display: none;

    div:first-child {
      display: flex;
      flex: 1;
      min-height: 0;
    }

    tr {
      td:first-child {
        font-weight: bold;
      }
    }
  }

  @media (min-width: 1000px) {
    padding: 0;

    .ant-table-wrapper {
      display: flex;
      flex: 1;
      min-height: 0;
    }

    .ant-list {
      display: none;
    }

    .SceneMainListActions {
      justify-content: center;
    }
  }
}
