.AboutModal {
  width: calc(100vw - 20px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  .AboutModal-description {
    font-size: 1rem;
  }

  .AboutModal-inner {
    margin-top: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    div:first-child {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 1000px) {
    width: auto;
  }
}
