@import "../../../../../../../../../../../variables";
@import "../../shared/ValidationMixin";

.PropertiesCollapse {

  .Property {

    .Header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .Text {
        font-weight: bold;
      }

      .Section2 {

        .ant-btn {
          margin-right: 10px;
          background-color: $purple-dark;
          border-color: $purple-dark;
          color: $white;
        }
      }
    }

    &.Valid {
      @include Valid;
    }

    &.Invalid {
      @include Invalid;
    }
  }
}