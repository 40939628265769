@import "../../../../../../../../../../variables";

.BannerMovieSelect {
  div:first-child {
    font-weight: bold;
  }
}

.BannerMovieSelect-dropdown {

  .BannerMovieSelect-dropdown-divider {
    margin: 1px 0;
  }

  .BannerMovieSelect-loadMore {
    color: $purple-light;
    padding: 4px 8px;
    cursor: pointer;
  }

  .BannerMovieSelect-spinner {
    margin: 4px 10px;
  }
}