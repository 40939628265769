.BaseVerticalRangePicker {

  .BaseVerticalRangePicker-date {
    margin-top: 10px;

    div:first-child {
      font-weight: bold;
    }

    .ant-calendar-picker {
      width: 100%;
    }
  }
}