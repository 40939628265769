@import "../../variables";

.LoginWrapper {
  height: calc(100vh - 56px);
  display: flex;
  align-items: center;
  background-color: $header-gradient-primary;
  background-image: linear-gradient(180deg, $header-gradient-primary 0%, $header-gradient-secondary 50%, $header-gradient-ternary 100%);

  @media (min-width: 1000px) {
    height: 100vh;
  }
}
