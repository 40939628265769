@import "../../../../variables";

.MainDashboardHeader {
  
  button {
    border-color: $white;
    font-weight: bold;
    margin-right: 5px;
  }

  &.header {
    &.ant-layout-header {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0;
      background-color: $header-gradient-primary;
      background-image: linear-gradient(225deg, $header-gradient-primary 0%, $header-gradient-secondary 50%, $header-gradient-ternary 100%);
      height: auto;

      .MainDashboardHeader-brand-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 0 0 15px;
      }

      .BaseHorizontalNavigation {
        display: none;
      }
    }

    .MainDashboardHeader-firstChild {
      display: flex;
      flex-direction: row;
      align-items: center;

      .anticon {
        margin-right: 20px;
      }
    }

    .ant-input-search {
      margin: 0 20px;
      width: 60%;
    }
    
    @media (min-width: 1000px) {
      .MainDashboardHeader-firstChild {
        .anticon {
          display: none;
        }
      }
      &.ant-layout-header {

        .BaseHorizontalNavigation {
          display: flex;
        }
      }
    }
  }
}

