.BannerFilters {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .InputWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;

    > div:first-child {
      font-weight: bold;
      margin-top: 5px;
    }

    input,
    .ant-select-selection {
      flex: 1;
    }
  }

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: center;

    .InputWrapper {
      align-items: center;
      flex-direction: row;

      > div,
      input {
        margin-right: 10px;
      }

      input,
      .ant-select-selection {
        width: 300px;
      }
    }
  }
}
