@import "../../../../../../../../../../variables.scss";

.NatsUsersViewHeader_ {
  .NatsUsersViewHeader-inner {
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: $white-grey;

    .NatsUsersViewHeader-mainWrapper {
      display: none;
      flex-direction: column;
      align-items: stretch;
      padding: 0 0 8px;
      
      @media (min-width: 1000px) {
        align-items: center;
        overflow-x: scroll;
      }

      &.FiltersToggled {
        display: flex;
      }

      .NatsUsersViewHeader-filterLabel {
        margin: 0 10px;
        font-weight: bold;
        min-width: max-content;
      } 

      .NatsUsersViewHeader-filterWrapper {
        display: flex; 
        align-items: center;

        .ant-select, input {
          width: 100vw;
          max-width: 140px;
        } 

        input {
          max-width: 200px;
        }

        .property-dropdown {
          max-width: 220px;
        }
      }
 
      button {
        margin-left: 0;
      }
    }

    .NatsUsersViewHeader-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      padding-right: 5px;

      .anticon {
        margin-left: 20px;
      }
    }

    @media (min-width: 1000px) {
      flex-direction: row;

      .NatsUsersViewHeader-mainWrapper {
        flex: 1;
        min-width: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
          margin-left: 10px;
        }
      }

      .NatsUsersViewHeader-inner {
        display: none;
        margin-bottom: 0;
      }
    }
  }
}
