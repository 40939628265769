@import 'src/variables';

.SubCategoriesView {
  padding: 10px;

  .SubCategoriesViewFilterWrapper {
    padding: 10px;
    display: flex;
    flex-direction: row;
  }

  .SubCategoriesViewActions {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 15px;
    }

    button {
      border-color: $purple-light;
      font-weight: bold;
    }
  }

  .TableWrapper {
    display: none;
  }

  .ListCardWrapper {
    .ListItemLabel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: bold;
      margin-right: 10px;
    }

    p {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  @media (min-width: 1000px) {
    padding: 0;

    .TableWrapper {
      display: block;
    }

    .ListWrapper {
      display: none;
    }

    .SubCategoriesViewActions {
      justify-content: center;
    }
  }
}