@import "../../../../../../../../../../variables";

.PropertiesBody {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $white;

  .ant-btn {
    border-color: $purple-light;
    margin-left: 5px;
  }

  .PropertiesBody-listCard-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .ant-btn {
      border-color: $purple-light;
    }
  }

  .NavigationLink {
    font-weight: bold;
    text-decoration: underline;
  }

  .ListView {
    padding: 10px;
    flex: 1;
    min-height: 0;
    overflow: auto;

    .ant-list-item {
      padding: 0;

      .BaseCard {
        margin-bottom: 10px;

        .BaseCard-body-children {
          .ListCardWrapper {
            .ListItemLabel {
              margin-right: 5px;
            }

            p {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
          }
        }
      }
    }

    .ant-list-item:last-child {
      .BaseCard {
        margin-bottom: 0;
      }
    }
  }

  .ant-table-wrapper {
    display: none;
    // flex: 1;
    min-height: 0;
    flex-direction: column;

    .Actions-Container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row !important;

      .ClearCacheBtn {
        background-color: $cache-button;
        color: $white;
      }

      .StatusItem {
        font-weight: bold;
        font-size: 1rem;
        padding: 5px;

        .Visible {
          background-color: $studio-enabled;
          color: $white;
        }

        .Hidden {
          background-color: $studio-disabled;
          color: $white;
        }
      }
    }

    div:first-child {
      // flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
    }

    .ant-table {
      .ant-table-thead > tr > th {
        background-color: #fafafa !important;
      }

      .ant-table-body {
        background-color: $white;
        overflow: auto;

        table {
          border-radius: 0;

          .ant-table-thead {
            border-radius: 0;

            tr:first-child {
              th:first-child {
                border-top-left-radius: 0;
              }

              th:last-child {
                border-top-right-radius: 0;
              }
            }
          }
        }
      }

      .ant-table-placeholder {
        display: none;
      }
    }
  }

  .EditPropertyCatalogBtn {
    border-color: $purple-light;
    margin-left: 5px;
  }

  @media (min-width: 1000px) {
    .ListView {
      display: none;
    }

    .ant-table-wrapper {
      display: flex;
    }
  }
}

.PropertiesActionDropdown li {
  line-height: 18px;
}
