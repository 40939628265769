.UploadDroppedFiledModal {
  width: calc(100vw - 50px);
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;

  .UploadDroppedFiledModal-actions {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    border-bottom: 1px solid lightblue;

    button:first-child {
      margin-right: 10px;
    }

    .ant-checkbox-wrapper {
      margin-left: 10px;
    }
  }

  .UploadDroppedFiledModal-images {
    margin-top: 10px;
    flex: 1;
    min-height: 0;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .MarkingImage {
      margin: 10px;
    }
  }
}

.UploadDroppedFiledModal-Footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:first-child {
    margin-right: auto;
  }
}