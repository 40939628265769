@import "../../variables";

.MainDashboard {

  .MainDashboard-inner {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  .DropZone {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white-grey;
    opacity: .9;

    .DropZone-inner {
      position: absolute;
      top: 24px;
      left: 24px;
      bottom: 24px;
      right: 24px;
      flex: 1;
      min-height: 0;
      min-width: 0;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: 2px dashed $purple-light;
      font-size: 48px;
      text-align: center;

      .anticon {
        font-size: 64px;
        margin-bottom: 10px;
      }
    }
  }
}