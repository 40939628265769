@import "../../../../../../../../../../../variables";
@import "../../shared/ValidationMixin";

.AdsCollapse {

  .ant-collapse-header {

    .Title {
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .PropertyAdParent {

    .ant-collapse-content-box {
      padding: 0 !important;

      .PropertyAd {
        border: none;
        border-radius: 0;
        margin-bottom: 10px;
        margin-right: 1px;

        .ant-card-head {
          border-bottom-width: 0;
          margin-top: 1px;
        }

        .BaseCard-body-children {
          padding: 0;

          tr {
            td:first-child {
              font-weight: bold;
            }
          }

          .AdTimeNotConfigured {
            color: $invalid-input;
            font-weight: bold;
          }
        }

        .BaseCard-body-footer {
          border-top-width: 0;

          .PropertyAdCardFooter {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .ant-btn {
              background-color: $purple-light;
              border-color: $purple-light;
              color: $white;
            }
          }
        }
      }
    }

    &.Valid {
      @include Valid;
    }
  }

  &.ValidationTriggered {

    .PropertyAdParent {

      .ant-collapse-header {
        color: $white !important;
        font-weight: bold;
      }

      &.Valid {

        .ant-collapse-header {
          color: $black !important;
          font-weight: bold;
        }
      }
    }
  }
}