@import "../../../../../../../../../variables";

.BannerDetails {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  transform: translate3d(0, 0, 0);

  button {
    border-color: $white;
    font-weight: bold;
    margin-right: 5px;
  }

  .TabsComponent {
    background-color: $white;
    flex: 1;
    min-height: 0;
    padding: 10px 0;

    .ant-tabs-bar {
      margin-bottom: 0;
    }

    .ant-tabs-tabpane {
      overflow: auto;

      .BannerDetailsImagesTab {
        height: 100%;
      }
    }

    @media (min-width: 1000px) {
      padding: 0 10px;
    }
  }
  
  .BannerDetailsFooter {
    display: none;
  }
  
  @media (min-width: 1000px) {
    .BannerDetailsFooter {
      display: flex;
    }
  }
}
