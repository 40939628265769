.EditDefaultBannerSetModal {
	.MainModalDialog {
		.ModalDialogBody {
			width: 500px;
			height: 75vh;
			overflow: auto;
			flex: auto !important;
			padding: 10px !important; 
		}

		.Option {
			margin-bottom: 15px;
			display: flex;
			flex-direction: column;
		}
	}
}