.BannerDetailsImagesTab {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .BaseImagesDisplay {
    height: 100%;
    width: 100%;

    .BaseImagesDisplayList {
      display: block;
      padding: 10px;
    }

    .ImagesGridDisplay-card {
      height: auto;
    }

    @media (min-width: 1000px) {
      .BaseImagesDisplayList {
        display: none;
      }
    }
  }
}