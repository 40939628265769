@import '../../../../../../../../../../variables';

.SceneDetailsView {
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .TabsComponent {
    background-color: $white;
    flex: 1;
    min-height: 0;

    .ant-tabs-tabpane:first-child {
      overflow: auto;
    }
  }

  .ImagesTab {
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .SceneDetailsImagesTab {
      flex: 1;
      min-height: 0;
    }
  }
}

@media (min-width: 1000px) {
  .SceneDetailsView {
    padding: 0;
  }
}
