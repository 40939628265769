@import "../../../../../../../../../../../../variables";
@import "../../../shared/ValidationMixin";

.ScenesCollapsePanel {

  .Scene {

    .Header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .SceneName {
        font-weight: bold;
      }

      .Section {

        .ant-btn {
          margin-right: 10px;
          border-color: $grey-light;
          background-color: $grey-light;
          color: $white;
        }
      }
    }

    .AdDetails {
      border-color: $purple-dark;
      padding: 1px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.Valid {
      @include Valid;
    }

    &.Invalid {
      @include Invalid;
    }
  }
}