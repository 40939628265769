.BaseHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .BaseHeader-title {
    font-weight: bold;
    font-size: 1rem;
  }

  .BaseDropdown {
    display: block;
    font-size: 20px;
  }

  @media (min-width: 1000px) {

    .BaseDropdown {
      display: none;
    }
  }
}

.BaseHeader-menu {

  .anticon {
    margin-right: 5px;
  }
}
