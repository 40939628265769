.EditAdConfigurationModal {
  width: calc(100vw - 100px);
  height: calc(100vh - 60px);
  max-width: 700px;
  max-height: 965px;

  .EditAdConfigurationModal-inner {
    overflow: auto;

    .AdConfigPanel {
      border-radius: 0;
      border-width: 0;

      .ant-card-head {
        display: none;
      }
    }
  }
}