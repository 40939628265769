@import "../../../../../../../../../../variables";

.NatsUserDetails {
  height: 100%;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  .BaseNavigationItem {
    button {
      border-color: $purple-light;
      font-weight: bold;
      margin-left: 5px;
    }
  }

  .NatsUserDetails-body {
    background-color: $white;
    padding: 0;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .ant-tabs {
      flex: 1;
      min-height: 0;
      transform: translate3d(0px, 0px, 0px);

      .ant-tabs-content {
        height: calc(100% - 50px);

        .NatsUserInfo {
          padding: 0 10px;
        }
      }
    }

    @media (min-width: 1000px) {
      padding: 0 10px;
    }
  }
}
