@import "../../../../variables";

.MainDashboardMainView {
  &.ant-layout {
    flex: 1;
    min-height: 0;
    background: $grey-dark;
    display: flex;
    flex-direction: row;
    transform: translate3d(0, 0, 0);
  }
}
