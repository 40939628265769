.MovieSpotlightModal {
  width: calc(100vw - 20px);
  max-height: calc(100vh - 130px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .MovieSpotlightModal-Inner {
    .Label {
      font-size: 16px;
      font-weight: bold;
    }
  }

  @media (min-width: 1000px) {
    width: 1000px;
    max-height: calc(100vh - 130px);
    padding: 20px;
  }
}