.PageNavigation {

  .ant-pagination {
    display: none;
  }

  .PageInput {
    display: flex;
  }

  @media (min-width: 1000px) {
    .ant-pagination {
      display: block;
    }

    .PageInput {
      display: none;
    }
  }
}