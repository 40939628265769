@import "../../../../../../../../../../variables";

.SceneSpotlightModal {
  height: 500px;
  width: calc(100vw - 20px);
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;

  .SceneSpotlightModal-Search {
    width: 100%;

    .Label {
      font-size: 16px;
      font-weight: bold;
    }

    .ant-input {
      min-height: auto;
    }
  }

  .Scenes-Container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 60px);
    flex-wrap: wrap;
    align-content: flex-start;
    min-height: 0;
    overflow: auto;
  }

  .BaseCard {
    width: calc(50% - 5px);
    margin-top: 10px;
    height: 230px;

    .ImageComponent {
      height: 150px;
      width: 100%;
    }

    .ant-radio-wrapper {
      span:last-of-type {
        font-weight: bold;
      }
    }
  }

  @media (min-width: 1000px) {
    width: 650px;
    height: 75vh;

    .BaseCard {
      width: calc(33% - 5px);
    }
  }
}
