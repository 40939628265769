@import "../../../../../../../../../../variables";

.MovieDetailsInfosTab {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .MovieDetailsInfosTab-primary {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: auto;
  }

  .MovieDetailsDescription {
    padding: 10px;
  }

  .MovieDetailsInfosTab-inner {
    display: flex;
    flex-direction: column;

    .MovieDetailsImage {
      border-bottom-width: 0;
      border-left-width: 0;
      height: auto;
      width: 100%; 
    }

    .MovieDetailsImage:first-child {
      display: inline-block;
    }

    .MovieDetailsImage:last-child {
      display: none;
    }

    .MovieDetailsInfosTabTable {
      tr:last-child {
        td {
          border-bottom-width: 0;
        }
      }
    }

    @media (min-width: 1000px) {

      .MovieDetailsImage {
        padding: 10px;
        height: 720px;
        width: auto;

        img {
          height: 100%;
          width: auto;
        }
      }

      .MovieDetailsImage:first-child {
        display: none;
      }

      .MovieDetailsImage:last-child {
        display: block;
      }
    }
  }

  .UserActions {
    background-color: #ccc;
    opacity: .8;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .ant-btn {
      border-color: $purple-light;
      margin-left: 5px;
    }
  }

  @media (min-width: 1000px) {
    .MovieDetailsInfosTab-inner {
      flex-direction: row;
    }
  }
}
