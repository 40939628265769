@import '../../../../../../../../../variables.scss';

.AddToSetModal {
  width: calc(100vw - 40px);
  max-height: 80vh;
  padding: 10px; 
  overflow: auto;

  .Label {
    font-weight: bold;
    font-size: 1rem;

    &.BannersSelection {
      margin-top: 10px;
    }
  }

  .BannersList {
    flex: 1;
    min-height: 0;
    border: 1px solid $ant-border;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    .BannersList-inner {
      flex: 1;
      min-height: 0;
      list-style: none;
      padding: 0;
      overflow: auto;
      margin-bottom: 0;

      li:nth-child(even) {
        background:$white-grey;
      }

      li {
        padding: 5px 5px 5px 10px;
      }

      li:hover {
        color: $purple-light
      }

      li:last-child {
        padding-bottom: 0;
      }

      .CheckBox {
        float: right;
      }
    }

    .LoadMore{
      display: flex;
      flex-direction: row;
      justify-content: center;
      border-top: 1px solid $ant-border;
      padding: 5px 0;

      span {
        color: $purple-light;
        cursor: pointer;
      }
    }
  } 
}
.AddToSetModal-actions {
  button:last-child:disabled {
    color: $purple-dark !important;
  }
}
