@import "../../../../../../../../../variables";

.PropertiesViewDetails {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  button {
    border-color: $white; 
    margin-right: 5px;
  }
}
