@import "../../../../../../../../../variables";

.VideoModal {
  height: calc(100vh - 50px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  transform: translate3d(0, 0, 0);

  .ant-card {
    border: none;
  }

  .FullPlayer {
    width: 100%;
    flex: 1;
    min-height: 0;
  }

  .ant-btn {
    font-weight: bold;
    border-color: $purple-light;
  }

  @media (min-width: 1000px) {
    height: 100vh;
    width: 100vw;
    align-items: stretch;
  }
}