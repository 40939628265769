.ChangeAltTagModal {
  width: 100vw;
  max-width: 500px;
  height: 80vh;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .ChangeAltTagModal-inner {
    margin-top: 5px;
    display: flex;
    flex-direction: column;

    div:first-child {
      margin-bottom: 10px;
    }
  } 
}

