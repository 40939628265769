.ModalContainer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;

  &.Visible {
    display: flex;
  }

  .InnerWrapper {
    width: 100%;
    height: 100%;

    .Background {
      width: 100%;
      height: 100%;
      background-color: lightgray;
      opacity: .7;
      position: absolute;
    }

    .ModalWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
}
