@import "../../../../../../../../../../../variables";

.MovieCard {

  .BaseCard-body-children {
    padding: 10px !important;
  }

  .CardHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .CardHeader-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h2 {
        color: $movie-card-header-color;
        margin-bottom: 0;
        margin-right: 10px;
      }

      .StatusDropdownWrapper {
        display: flex;
        flex-direction: column;

        .MovieOnline {
          width: 101px;
          background-color: $movie-online;
          color: $white;
          margin-right: 0;
          margin-bottom: 5px;
        }

        .MovieOffline {
          width: 101px;
          background-color: $movie-offline;
          color: $white;
        }

        .MovieEnabled {
          background-color: $movie-enabled;
          color: $white;
        }

        .MovieDisabled {
          background-color: $movie-disabled;
          color: $white;
        }
      }
    }


    .UserActions {
      display: flex;
      flex-direction: row;
      align-items: center;

      .ViewMovieDetails {
        display: none;
      }

      .ant-btn {
        border-color: $purple-light;
        margin-left: 5px;
      }
    }
  }

  @media (min-width: 1000px) {
    .CardHeader {
      flex-direction: row;

      .CardHeader-title {
        justify-content: initial;
        align-items: flex-start;

        .StatusDropdownWrapper {
          flex-direction: row;

          .MovieOnline {
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
      }

      .UserActions {

        .ViewMovieDetails {
          display: flex;
        }
      }
    }
  }

  .CardBody {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      height: 100%;
    }

    .ImageComponent {
      cursor: pointer;
      height: 100%;
      width: auto;

      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      }

      @media (min-width: 1000px) {
        height: 280px;
        width: 200px;
      }
    }

    .CardContent {
      display: flex;
      flex-direction: row;
      flex: 0.7;
      flex-grow: 1;
      flex-wrap: wrap;

      .MovieInfoWrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;

        .CardSection {
          margin-top: 15px;
          margin-left: 0;
        }

        button {
          display: block;
          margin-top: 30px;
          border-color: $purple-light;
        }

        @media (min-width: 1000px) {
          .CardSection {
            margin-top: 0;
            margin-bottom: 10px;
          }

          button {
            display: none;
          }
        }
      }

      @media (min-width: 1000px) {
        .MovieInfoWrapper {

          .CardSection {
            margin-left: 30px;

            .ant-table-tbody {
              td {
                padding: 4px 4px;
              }
            }
          }
        }
      }
    }
  }
}

.MovieCard-enabledDisabled {

  &.ant-dropdown-menu {
    padding: 0 2px 2px 2px;

    li:first-child {
      background-color: $movie-enabled;
      color: $white;
    }

    li:first-child:hover {
      font-weight: bold;
    }

    li:last-child {
      background-color: $movie-disabled;
      color: $white;
    }

    li:last-child:hover {
      font-weight: bold;
    }
  }
}
