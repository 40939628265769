@import "../../../../../../../../../../../../variables";

.CardSection {
  .ant-table-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .ant-table {

    &.ant-table-bordered {
      .ant-table-title {
        border-color: $purple-light;
        border-left-width: 5px;
        padding-left: 10px;
      }

      .ant-table-body {

        table {
          border-left-color: $purple-light;
          border-left-width: 5px;
          border-top-color: $purple-light;

          tr {
            td:first-child {
              font-weight: bold;
            }

            td:last-child {
              border-right-color: $purple-light;
            }
          }

          tr:nth-child(odd) {
            background: $white-grey;
          }

          tr:last-child {
            td {
              border-bottom-color: $purple-light;
            }
          }
        }
      }
    }
  }
}
