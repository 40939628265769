@import "../../../../variables";

.MarkingImage {
  width: 330px;
  height: 360px;

  .ImageContainer {
    display: flex;
    justify-content: center;

    .anticon {
      position: absolute;
      right: 0;
      margin-top: 10px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .MarkingImage-file-mark {
    font-size: 84px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .MarkingImage-file-mark-background {
      margin: -1px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      flex: 1;
      min-width: 0;
      background-color: $grey-light;
      opacity: .8;
    }

    .anticon {
      color: #39FF14;
      z-index: 1;
    }
  }
}