@import "../../../../../../../../../../../variables";

.AdConfigurationPanel {
  padding: 10px;
  border-radius: 5px;

  &.ant-card-bordered {
    border: 1px solid $purple-light;
  }

  .ant-card-head {
    margin-right: 1px;
    margin-left: 1px;
    margin-top: 1px;
  }

  .Text {
    font-weight: bold;
    margin-bottom: 3px;
    margin-top: 8px;
  }

  .AddHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .AdStartTime {
    .Text {
      font-weight: bold;
    }

    .AdStartTime-config {
      display: flex;
      flex-direction: row;

      .ant-slider {
        margin-right: 20px;
        flex: 1;
        min-width: 0;
      }
    }

    .Search {
      display: flex;
      flex-direction: row;

      .ant-input {
        width: 130px;
      }
    }
  }

  .AdType {
    .Text {
      font-weight: bold;
    }

    .ant-select {
      width: 100%;
    }
  }

  .VideoCategory {
    .ant-select {
      width: 100%;
    }
  }

  .ImageDuration {

    .ant-input-number {
      width: 100%;
    }
  }
}
