@import "../../variables";

.ImageActions {
  transform: translate3d(0, 0, 0);
  cursor: pointer; 

  .Actions {
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(102, 51, 153, 0.2);
    display: none;
    flex-direction: column;
    justify-content: flex-end;

    button {
      margin-bottom: 5px;
      margin-left: 5px;

      &:first-child {
        margin-top: 10px;
      }
    }
  }

  &:hover {
    .Actions {
      display: flex;
    }
  }

  .ImageDimension {
    font-size: 1.2em;
    font-weight: 600;
  }
}
