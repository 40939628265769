@import "../../../../../variables";

.Company {
  cursor: pointer;

  .CompanyName {
    font-weight: bold;
    color: $white;
    font-size: 1.5rem;
  }
}
