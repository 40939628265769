@import "../../../../../../../../../variables";

.AddUserModal {
  width: calc(100vw - 20px);
  height: 75vh;
  padding: 10px; 
  overflow: auto;

  .AddUserModal-field-wrapper:first-child {
    margin-top: 0;
  }

  .AddUserModal-field-wrapper {
    line-height: normal;
    margin-top: 15px;

    .Label {
      font-weight: bold;
      font-size: 1rem;
    }

    .ant-select {
      width: 100%;
    }
  }

  @media (min-width: 1000px) {
    width: 500px; 
    padding: 20px;
  }
}
.AddUserModal-actions {
  button:last-child:disabled {
    color: $purple-dark !important;
  }
}
