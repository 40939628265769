@import '../../../../../../../../../variables';

.PayoutsByProperty {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  height: 100%;
  transform: translate3d(0, 0, 0);

  .PayoutsByProperty-inner {
    flex: 1;
    min-height: 0;

    .PayoutsByProperty-listCard-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .BaseNavigationItem {
      button:first-child {
        margin-right: 10px;
      }

      button {
        font-weight: bold;
        border-color: $purple-light;
      }
    }

    > :first-child {
      height: 100%;
      overflow: auto;
    }

    .PayoutsByPropertyMainView {
      height: 100%;
    }
  }
}
