@import "../../../../../../../../../../../variables";

.MainDialog {
  width: 90vw;
  height: 90vh;

  .ModalDialogTitle {

    > .Title {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .ant-btn {
        margin-right: 10px;
      }
    }
  }

  .MainDialog-inner {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    align-items: stretch;
    overflow: auto;

    .NoMovieSelectedIndicator {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .NoMovieSelectedIndicator-text {
        font-weight: bold;
        font-size: 40px;
      }

      .ant-btn {
        font-size: 25px;

        span {
          text-decoration: underline;
        }
      }
    }

    .MovieItem {

      .MoviePanelHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: bold;
        align-items: center;

        .Section2 {
          display: flex;
          flex-direction: row;
          align-items: center;

          .ant-btn {
            background-color: $purple-light;
            color: $white;
            border-color: $purple-dark;
            margin-right: 10px;
          }

          .anticon {
            font-size: 18px;
          }
        }
      }

      .Property {
        margin-bottom: 10px;

        .PropertyCardHeader {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .PropertyName {
            font-weight: bold;
          }

          .Section2 {
            display: flex;
            flex-direction: row;
            align-items: center;

            .ant-btn {
              border-color: $purple-light;
              margin-right: 10px;
            }

            .anticon {
              font-size: 16px;
            }
          }
        }

        .Scene {

          .SceneHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .SceneAd {

            .SceneAdHeader {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }

      .Property:last-child {
        margin-bottom: 0;
      }
    }
  }

  .MainDialog-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
    min-width: 0;

    &.Spaced {
      justify-content: space-between;
    }
  }
}