.BannerSetProperties {
  height: 100%;
  width: 100%;
  transform: translate3d(0, 0, 0);
  align-items: stretch;
  display: flex;
  flex-direction: column;
 
  > div:nth-child(2) {
    flex: 1;
    min-height: 0;
  }

  // .ant-input-affix-wrapper {
  //   max-width: 200px;
  // }
}