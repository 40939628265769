.EditAdModal {
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  max-width: 700px;
  max-height: 700px;

  .Header {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .EditAdModal-inner {
    overflow: auto;

    .AdConfigPanel {
      border: none;
      border-radius: 0;

      .ant-card-head {
        display: none;
      }
    }
  }
}
