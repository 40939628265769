@import "../../../../../../../../../../../variables";

.MovieSelectionModal {
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  max-width: 1000px;
  max-height: 1000px;

  .MovieSelectionModal-inner {
    flex: 1;
    min-height: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .MovieSelectionDescription {
      font-weight: bold;
      margin-bottom: 30px;
      font-size: 18px;
    }

    .FilterWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      padding: 0 20px;

      .Label {
        font-weight: bold;
        white-space: nowrap;
        margin-right: 10px;
        width: 150px;
        min-width: 150px;
      }
    }

    .MoviesList {
      overflow: auto;
      flex: 1;
      min-height: 0;

      .MovieItem {
        border: 1px solid $ant-border;
        padding: 8px;
        border-radius: 5px;
        margin-bottom: 2px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .MovieTitle {
          font-weight: bold;
        }

        &.Checked {
          background-color: $blue-lighter;
        }

        &:hover {
          background-color: $blue-light;
        }
      }
    }
  }
}
