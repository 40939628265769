@import '../../../../../../../../../../variables.scss';

.BannersViewFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;

  .BannersViewFooter-maxView {
    display: none;

    button {
      margin-right: 15px;
      border-color: $purple-light;
      font-weight: bold;
    }
  }

  .BannersViewFooter-minView {
    button {
      border-color: $purple-light;
      font-weight: bold;
    }
  }

  @media (min-width: 1000px) {

    .BannersViewFooter-maxView {
      display: flex;
    }

    .BannersViewFooter-minView {
      display: none;
    }
  }
}

.BannersViewFooter-dropdownMenu {
  .anticon {
    margin-right: 5px;
  }
}
