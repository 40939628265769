.MovieDetailsScenesTab {

  .MovieScene {
    margin-bottom: 10px;
  }

  .MovieScene:last-child {
    margin-bottom: 0;
  }

}

