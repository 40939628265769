.BrowseConfigurationAddNewItemModal {
  width: 100vw;
  max-width: 40em;

  .BrowseConfigurationAddNewItemModal-inner {
    padding: 20px;
    max-height: 70vh;
    overflow: auto;

    .InputField {
      .Label {
        font-weight: bold;
      }
    }

    .ant-input {
      margin-bottom: 10px;
    }

    .ant-checkbox-wrapper {
      margin: 0.3em 0;
      display: block;

      &:hover {
        background-color: rgba(0, 0, 0, .05);
      }
    }
  }
}