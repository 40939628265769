@import "../../../../../../../../../../../../variables";
@import "../../../shared/ValidationMixin";

.AdsCollapsePanel {

  .Title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .SceneAd {

    .ant-collapse-content-box {
      padding: 0 !important;

      .AdDetails {
        border: none;

        .ant-card-head {
          display: none;
        }
      }
    }

    &.Valid {
      @include Valid;
    }
  }

  &.ValidationTriggered {

    .SceneAd {

      .ant-collapse-header {
        color: $white !important;
        font-weight: bold;
      }

      &.Valid {

        .ant-collapse-header {
          color: $black !important;
          font-weight: bold;
        }
      }
    }
  }
}