@import '../../../../../../../../../../variables';

.LicensorInfo {
  overflow: auto;
  padding: 0 10px;
  background-color: $white;

  .BaseCard {
    margin: 1.5em 0;
  }

  .BaseCard .BaseCard-body .BaseCard-body-children {
    padding: 0;
  }

  .Royalties-Container.BaseCard {
    .Input-Container {
      display: flex;
      align-items: center;

      .ant-input-number {
        margin-right: 5px;
      }
    }
  }

  .ant-table-footer {
    padding: .2em .5em;

    .InfoTableFooter {
      text-align: right; 
    }
  }

  .EditBtn {
    border-color: #7a7de5;
  }
}