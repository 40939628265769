@import "../../variables";

.ConfigModal {
  width: 400px;

  .ant-card-bordered {

    .ant-card-body {

      .ModalDialogBody {
        padding: 10px;

        input {
          display: none;
        }

        .ant-btn {
          font-weight: bold;
          border-color: $purple-light;
        }

        .ant-btn:first-child {
          margin-bottom: 15px;
        }
      }
    }
  }
}
