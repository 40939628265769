@import "../../../../../../../../../variables";

.MoviesViewList {
  background-color: $white;
  padding: 10px;

  > .ant-list-items {
    :first-child {
      margin-top: 5px;
    }
  }

  .MovieCard {
    margin-bottom: 10px;
    border-color: $purple-light;
  }

  .MovieCard:last-child {
    margin-bottom: 0;
  }

  .StickyHeader {
    position: absolute;
    left: 0;
    z-index: 50;
    width: 100%;
    padding: 0 10px;
    display: block;

    .StickyHeaderInner {
      background-color: $white;
      width: 100%;
      color: $purple-light;
      font-size: 1.5em;
      font-weight: bold;
      padding: 10px;
    }

    @media (min-width: 500px) {
      display: none;
    }
  }
}
