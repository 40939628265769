.ErrorsFoundDialog {
  width: 500px;

  .ErrorsFoundDialog-inner {
    padding: 20px;
    font-weight: bold;

    .Title {
      font-size: 20px;
    }
  }
}