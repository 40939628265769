.LoadingWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: none;
    top: 0;
    background-color: lightgray;
    opacity: .7;
    justify-content: center;
    align-items: center;
}

.LoadingWrapper div:nth-child(1) {
    margin-right: 10px;
}

.LoadingWrapper div:nth-child(1) div:nth-child(1) {
    margin: 0;
}

.LoadingWrapper div:nth-child(1) div:nth-child(1):after {
    background: lightgray;
}

.Visible {
    display: flex;
}
