@import '../../../variables';

.BaseImagesDisplayTable {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .ant-table-wrapper {
    flex: 1;
    min-height: 0;

    table {
      td:nth-child(2) {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .ImageColumn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;

      &:hover {
        .BaseImagesDisplayTable-actions {
          display: flex;
          cursor: pointer;
        }
      }
  
      .BaseImagesDisplayTable-actions {
        display: none;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 10px;
  
        .BaseImagesDisplayTable-overlay {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          min-width: 0;
          background-color: $grey-light;
          opacity: .8;
        }
      }

      .ImageComponent {
        width: 170px;
        height: 120px;

        img {
          width: auto;
        }
      }
    }
  }
}