@import "../../../../../../../../../../../../../variables";
@import "../../../../shared/ValidationMixin";

.ConfigureAdsModal {
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  max-width: 600px;
  max-height: 800px;

  .ConfigureAdsModal-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    min-width: 0;
  }

  .ConfigureAdsModal-inner {
    padding: 10px;
    flex: 1;
    min-width: 0;
    overflow: auto;

    .AdConfigCollapse {

      .SceneAdConfigHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;

        .ant-btn {
          margin-right: 10px;
          background-color: $purple-light;
          color: $white;

          &:disabled {
            background-color: $grey-light;
          }
        }
      }

      .ant-collapse-content-box {
        padding: 0;
      }

      .AdConfigPanel {
        border-radius: 0;
        border: none;

        .ant-card-head {
          display: none;
        }
      }

      &.Valid {
        @include Valid
      }

      &.Invalid {
        @include Invalid
      }
    }
  }

  .ConfigureAdsModal-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    min-width: 0;

    .Section2 {
      display: flex;
      flex-direction: row;
    }
  }
}
