@import "../../../../../../../../../variables";

.BannerSets {
  transform: translate3d(0, 0, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;

  >:nth-child(1) {
    flex: 1;
    min-height: 0;
    overflow: auto;
  }
}