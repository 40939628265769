@import "../../../../../../../../../../variables";

.StarsViewHeader {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-weight: bold;
  justify-content: flex-start;
  padding: 0 10px;

  .StatusInnerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;
    padding: 10px 0;
    justify-content: space-between;

    .StatusInfo {
      flex: 1;
      color: $white;
      padding-left: 0;

      .RecordCountMessage {
        font-weight: bold;
      }
    }

    .anticon {
      display: inline-block;
      font-size: 20px;
      margin-right: 5px;
    }

    @media (min-width: 1050px) {
      .anticon {
        display: none;
      }
    }
  }

  .StarFiltersWrapper {
    display: none;
    margin-bottom: 10px;
  }

  &.FilterDisplayed {
    .StarFiltersWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    @media (min-width: 1050px) {
      .StarFiltersWrapper {
        flex-direction: row;
      }
    }
  }

  @media (min-width: 1050px) {
    .StarFiltersWrapper {
      display: flex;
      margin-bottom: 0;
    }

    flex-direction: column;
  }
}

@media (min-width: 1050px) {
  .StarsViewHeader {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }
}
