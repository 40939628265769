@import "../../variables";

.NotFound {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $not-found-background-color;
    padding: 20px;

    .MainText {
        font-size: 200px;
        color: $white;
        display: flex;
        flex-direction: row;
        padding-bottom: 0;
    }

    .SecondaryText {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $not-found-secondary-text-color;
        font-size: 30px;
    }
}

@media (min-width: 500px) {

    .NotFound {
        flex-direction: row;
    }

    .MainText {
        font-size: 250px;
        padding-bottom: 40px;
    }

    .SecondaryText {
        font-size: 40px;
        align-items: start;
    }

}
