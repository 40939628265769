@import '../../../../../../../../../variables.scss';

.LicensorDetails {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  transform: translate3d(0, 0, 0);

  button {
    border-color: $white; 
    margin-right: 5px;
  }

  > .ant-card {
    flex: 1;
    min-height: 0;
    border-radius: 15px 10px 10px 10px;
    border-color: $purple-light;
    border-left-width: 1px;
    display: flex;
    flex-direction: column;

    > .ant-card-head {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background-color: $purple-light;
      color: $white;

      .ant-card-head-title {
        font-weight: bold;
        font-size: 1.5rem;

        .LicensorDetails-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .LicensorDetails-title-dropdown {
            display: block;
          }

          @media (min-width: 1000px) {
            .LicensorDetails-title-dropdown {
              display: none;
            }
          }
        }
      }
    }

    > .ant-card-body {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      padding: 20px 10px;

      .LicensorDetailsFooter {
        display: none;
      }

      @media (min-width: 1000px) {
        .LicensorDetailsFooter {
          display: flex;
        }
      }
    }

    @media (min-width: 1000px) {
      border-left-width: 5px;
    }
  } 
}

.LicensorDetails-title-menu {
  .anticon {
    margin-right: 5px;
  }
}
