@import "../../../../../../../../../../variables";

.ManufacturerDetailsTable {

  tr {

    &:nth-child(1), &:nth-child(3) {
      height: 66px;
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }

    .ant-select {
      width: 100%;
    }
  }

  .ant-table-footer {
    padding: 10px;

    .Footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .ant-btn {
        border-color: $purple-light;
      }
    }
  }
}