@import "../../../../../../../../../../variables";

.PlayerConfigurationModal {
  width: 500px;

  .PlayerConfigurationModal-inner {
    overflow: auto;
    padding: 10px;

    .Header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .SelectAllBtn {
        margin-right: 2px;
        margin-bottom: 10px;

        .ant-checkbox {
          margin-left: 5px;
        }
      }
    }

    .ant-table-row {
      cursor: pointer;
      font-weight: bold;
    }
  }

  .Actions {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}